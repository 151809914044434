import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.scss';
import { PoorTextContent } from '../../../generated/StdLib';
import { PoorText } from '2mr-cms-frontend';

interface Props {
    list: {
        label: PoorTextContent,
        url?: string,
    }[],
}

export const Tags = (props: Props) => {

    const elems = props.list.map((tag, i) => {
        return <li key={i} className={styles.tag}>
            {tag.url ?
                <Link to={tag.url}>
                    <PoorText content={tag.label}/>
                </Link> :
                <PoorText content={tag.label}/>
            }
        </li>;
    });
    return (
        <ul className={styles.tagList}>
            {elems}
        </ul>
    );
};
