import { JsonObjectType, optional } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';
import {
    CardContent,
    HeaderCommon,
    PersonCardContent,
    ThreeColumnGridBlockContent,
    TwoColumnsCardContent
} from './Common';

const defineType = typeDefiner('Solutions.ts');

const KeyObjectiveContent: JsonObjectType = {
    title: 'text',
    subheader: optional('text'),
    description: 'richText',
    cardsContent: [CardContent],
};

defineType(
    KeyObjectiveContent,
    'KeyObjectiveContent',
);

const AccentBlockWithPokingOutImageContent: JsonObjectType = {
    text: 'richText',
    imageUrl: 'image',
};

defineType(
    AccentBlockWithPokingOutImageContent,
    'AccentBlockWithPokingOutImageContent',
);

const AdvisoryBlockContent: JsonObjectType = {
    title: 'text',
    subtitle: 'richText',
    description: 'richText',
    personCards: [PersonCardContent],
};

defineType(
    AdvisoryBlockContent,
    'AdvisoryBlockContent',
);

const OurProjectsContent: JsonObjectType = {
    title: 'text'
};

defineType(
    OurProjectsContent,
    'OurProjectsContent',
);

const LaunchProcessStep: JsonObjectType = {
    title: 'text',
    description: 'richText'
};
defineType(
    LaunchProcessStep,
    'LaunchProcessStep',
);

const LaunchProcessContent: JsonObjectType = {
    title: 'text',
    steps: {
        first: LaunchProcessStep,
        second: LaunchProcessStep,
        third: LaunchProcessStep,
        fourth: LaunchProcessStep,
        fifth: LaunchProcessStep,
        sixth: LaunchProcessStep,
    }
};
defineType(
    LaunchProcessContent,
    'LaunchProcessContent'
);

const SolutionsTabsContent: JsonObjectType = {
    tabs: [{
        title: 'text',
        url: 'url',
        description: TwoColumnsCardContent
    }]
};

defineType(
    SolutionsTabsContent,
    'SolutionsTabsContent'
);

const SolutionsLaunchpadContent: JsonObjectType = {
    header: HeaderCommon,
    keyObjectiveContent: KeyObjectiveContent,
    launchNftGridContent: ThreeColumnGridBlockContent,
    launchpadOperations: KeyObjectiveContent,
    accentBlockWithPokingOutImage: AccentBlockWithPokingOutImageContent,
    launchProcess: LaunchProcessContent,
    advisoryBlockContent: AdvisoryBlockContent,
    ourProjectsContent: OurProjectsContent
};

defineType(
    SolutionsLaunchpadContent,
    'SolutionsLaunchpadContent',
);

const MergingWorldsContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    cards: [CardContent]
};

defineType(
    MergingWorldsContent,
    'MergingWorldsContent'
);

const ShapeTheFutureItem: JsonObjectType = {
    iconUrl: 'image',
    description: optional('richText'),
    bigText: 'text'
}
defineType(
    ShapeTheFutureItem,
    'ShapeTheFutureItem'
);


const ShapeTheFutureContent: JsonObjectType = {
    title: 'text',
    description: optional('richText'),
    list: [ShapeTheFutureItem]
};

defineType(
    ShapeTheFutureContent,
    'ShapeTheFutureContent'
);

const SolutionsMetaverseContent: JsonObjectType = {
    header: HeaderCommon,
    mergingWorlds: MergingWorldsContent,
    shapeTheFuture: ShapeTheFutureContent,
    benefitsToBrands: ThreeColumnGridBlockContent,
};

defineType(
    SolutionsMetaverseContent,
    'SolutionsMetaverseContent',
);

export const SolutionsContent: JsonObjectType = {
    tabs: SolutionsTabsContent,
    launchpad: SolutionsLaunchpadContent,
    metaverse: SolutionsMetaverseContent,
};

defineType(
    SolutionsContent,
    'SolutionsContent',
);

export const importSolutionsContent = () => {
};
