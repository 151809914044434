import React, {useEffect, useState} from "react";
import {Article, } from "../component/pages/newsSingle/article/Article";
import { NEWS_URL } from "../data/Urls";
import {RecentNewsBlockSmart} from "../component/general/recentNewsBlockSmart/RecentNewsBlockSmart";
import { fetchNewsSingle} from "../utils/fetch";
import {NewsArticleContent} from "../generated/NewsContent";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "../component/general/loader/Loader";
import { NewsHeaderContent } from '../generated/NewsHeader';

type Props = {
    content: NewsHeaderContent,
}

export const NewsSinglePage = ({
    content
}: Props) => {
    const [articleContent, setArticleContent] = useState<NewsArticleContent>()
    const { slug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!slug){
            navigate(NEWS_URL);
            return;
        }
        fetchNewsSingle(slug).then((responseArray: NewsArticleContent[]) => {
            if(responseArray.length < 1){
                throw new Error('Content not found: ' + slug)
            }
            return responseArray[0];
        }).then(newsSingle => {
            setArticleContent(newsSingle);
        }).catch(e => {
            navigate(NEWS_URL);
        });
    }, [slug]);

    if (!articleContent) {
        return <Loader/>;
    }
    return (
        <>
            <Article
                {...articleContent}
                shareTargets={content.shareTargets}
                backButton={{label: 'Back', url: NEWS_URL}}
            />

            <RecentNewsBlockSmart title={content.readNextHeader} buttonLink={{label: undefined, url: NEWS_URL}} hideBtn={true}/>
        </>
    )
}
