import styles from './QuestionsChapter.scss';
import React from 'react';
import { FAQChapter } from '../../../../generated/FAQ';
import { Question } from '../question/Question';
import { clsx, PoorText } from '2mr-cms-frontend';
import { ShownOrHidden } from '../../../../generated/Common';

type QuestionsChapterProps = {
    chapter: FAQChapter,
    selectedChapter: string,
    defaultState: ShownOrHidden,
}

export const QuestionsChapter = ({
    chapter,
    selectedChapter,
    defaultState,
}: QuestionsChapterProps) => {
    const isSelected = '#' + chapter.title === selectedChapter;
    return <div className={clsx(
        styles.questionsChapter,
        isSelected && styles.selected,
        !isSelected && styles.notSelected,
    )}>
        <div className={styles.title}>
            <PoorText content={chapter.title}/>
        </div>
        <div className={styles.questions}>
            {chapter.content.map((question, i) => <Question key={i} defaultState={defaultState} {...question}/>)}
        </div>

    </div>;
};
