import React from 'react';
import { MapData } from '../../../../generated/ContactUs';

import styles from './Map.scss';
import { PoorText, RichText } from '2mr-cms-frontend';

export const Map = ({
    title,
    credentials,
    mapFrame,
    subtitle,
}: MapData) => {
    return <div className={styles.mapBlock}>
        <RichText content={mapFrame} element={'div'} className={styles.iframe}/>
        <div className={styles.contacts}>
            <div className={styles.header}>
                <PoorText content={title}/>
            </div>
            <div className={styles.credentials}>
                {credentials.map((cred, i) => <div key={i} className={styles.credential}>
                    <div className={styles.key}>{
                        <RichText content={cred.key} element={'div'}/>
                    }</div>
                    <div className={styles.value}>{
                        <RichText content={cred.value} element={'div'}/>
                    }</div>
                </div>)}
            </div>
            <div className={styles.subtitle}>
                <RichText content={subtitle} element={'div'}/>
            </div>
        </div>
    </div>;
};
