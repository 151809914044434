import React from "react";
import styles from "./style.scss";
import { ContactUsBlockContent, ContactUsCardContent } from '../../../../generated/ContactUsBlock';
import { PoorText, RichText } from '2mr-cms-frontend';

export const ContactUsBlock = (props: ContactUsBlockContent) => {

    const cards = props.cards.map((card, i) => {
        return <ContactUsCard key={i} {...card} />
    })
    return (
        <div className={styles.contactUs}>
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <h2 className={styles.title}>
                        <PoorText content={props.title}/>
                    </h2>
                    <RichText content={props.description} element={'div'} className={styles.description}/>
                </div>
                <div className={styles.cardsList}>
                    {cards}
                </div>
            </div>

        </div>
    )
};

const ContactUsCard = (props: ContactUsCardContent) => {
    const linkArrowClassNames = {
        'top-right': styles.topRight,
        'right': styles.right,
    }
    const links = props.links.map((link, i) => {
        return (
            <li className={styles.cardLinkItem} key={i}>
                <a href={link.href} className={styles.cardLink + ' ' + (link.arrow &&
                    linkArrowClassNames[link.arrow as ('right' | 'top-right')] || '')}>
                    <PoorText content={link.label}/>
                </a>
            </li>
        )
    })
    return (
        <div className={styles.contactUsCard}>
            <div className={styles.cardTitle}>
                <PoorText content={props.title}/>
            </div>
            <div className={styles.cardDescription}>
                <PoorText content={props.description}/>
            </div>
            <ul className={styles.cardLinksList}>
                {links}
            </ul>
        </div>
    )
}
