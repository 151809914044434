import { ProjectContentDefinition, ProjectPreviewContentDefinition } from '../../../common/src/types/Projects';
import { ProjectContent, ProjectPreviewContent } from '../generated/ProjectsContent';
import { NewsArticleContent, NewsArticlePreviewContent } from '../generated/NewsContent';
import { NewsArticleContentDefinition, NewsArticlePreviewContentDefinition } from '../../../common/src/types/News';
import { prepareContent } from '2mr-cms-frontend';
import { JsonObjectType } from '2mr-cms-typegen';

function prepare<T> (
    items: T[],
    tree: string,
    def: JsonObjectType
): T[] {
    return items.map(e => prepareContent(
        e,
        tree,
        '.' + (e as any).__index,
        def
    ) as T);
}

export function fetchNewsList (offset = 0, count = 4, tag?: string,) {
    const baseURl = window.location.origin;
    const url = new URL('/getNews', baseURl);
    if (tag) {
        url.searchParams.append('key', 'tags');
        url.searchParams.append('value', encodeURIComponent(tag));
    }
    url.searchParams.append('offset', offset.toString());
    url.searchParams.append('count', count.toString());

    return fetch(url)
        .then(r => r.json())
        .then((r: NewsArticlePreviewContent[]) =>
            prepare(r, 'news', NewsArticlePreviewContentDefinition)
        );
}

export function fetchNewsSingle (slugId: string) {
    const baseURl = window.location.origin;
    const url = new URL('/getNews', baseURl);
    url.searchParams.append('key', 'slug');
    url.searchParams.append('value', slugId.toString());

    url.searchParams.append('enrich', '1');
    url.searchParams.append('full', '1');

    return fetch(url)
        .then(r => r.json())
        .then((r: NewsArticleContent[]) =>
            prepare(r, 'news', NewsArticleContentDefinition)
        );
}

export const fetchAllProjects = (): Promise<ProjectPreviewContent[]> => {
    const baseURl = window.location.origin;
    const url = new URL('/getProjects', baseURl);
    url.searchParams.append('offset', '0');
    url.searchParams.append('count', '8');

    return fetch(url)
        .then(r => r.json())
        .then((r: ProjectPreviewContent[]) =>
            prepare(r, 'projects', ProjectPreviewContentDefinition)
        );
};

export const fetchSingleProject = (slug: string): Promise<ProjectContent[]> => {
    const baseURl = window.location.origin;
    const url = new URL('/getProjects', baseURl);
    url.searchParams.append('key', 'slug');
    url.searchParams.append('value', slug.toString());
    url.searchParams.append('enrich', '1');
    url.searchParams.append('full', '1');

    return fetch(url)
        .then(r => r.json())
        .then((r: ProjectContent[]) =>
            prepare(r, 'projects', ProjectContentDefinition)
        );
};