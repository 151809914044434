import {JsonObjectType, optional} from '2mr-cms-typegen';
import { typeDefiner} from '2mr-cms-typegen';
import {
    CardContent,
    HeaderCommon,
    PersonCardContent,
    TwoColumnsCardContent
} from "./Common";

const defineType = typeDefiner('Company.ts');

const StoryCardContent: JsonObjectType = {
    image: 'image',
    title: 'text',
    date: 'text',
    description: 'richText',
}

defineType(
    StoryCardContent,
    'StoryCardContent',
);

const CompanyValuesContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    cardsContent: [CardContent]
};

defineType(
    CompanyValuesContent,
    'CompanyValuesContent',
);

const CompanyStoryContent:JsonObjectType = {
    title: "text",
    description: 'richText',
    list: [StoryCardContent],
    listHeading: 'text',
    listHeading2: 'text',
}
defineType(
    CompanyStoryContent,
    'CompanyStoryContent',
);

const CompanyTeamContent:JsonObjectType = {
    title: 'text',
    description: optional('richText'),
    personCards: [PersonCardContent],
}

defineType(
    CompanyTeamContent,
    'CompanyTeamContent',
);

const CompanyGrowthContentItem: JsonObjectType = {
    iconUrl: 'image',
    description: 'richText',
    bigText: 'richText',
}
defineType(
    CompanyGrowthContentItem,
    'CompanyGrowthContentItem',
);

const CompanyGrowthContent:JsonObjectType = {
    title: 'text',
    description: 'richText',
    list: [CompanyGrowthContentItem],
    list2: [CompanyGrowthContentItem],
    list1Heading: 'text',
    list2Heading: 'text',
}

defineType(
    CompanyGrowthContent,
    'CompanyGrowthContent',
);

export const CompanyContent: JsonObjectType = {
    header: HeaderCommon,
    ourMission: TwoColumnsCardContent,
    story: CompanyStoryContent,
    values: CompanyValuesContent,
    team: CompanyTeamContent,
    advisory: CompanyTeamContent,
    growth: CompanyGrowthContent,
};

defineType(
    CompanyContent,
    'CompanyContent',
);


export const importCompanyContent = () => {

}
