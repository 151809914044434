import React from 'react';
import { Header } from '../component/pages/home/header/Header';
import { PotentialMaximise } from '../component/pages/home/potentialMaximise/PotentialMaximise';
import { HomeLabsPhasesBlock } from '../component/pages/home/homeLabsPhasesBlock/HomeLabsPhasesBlock';
import { TrustedByBlock } from '../component/pages/home/trustedByBlock/TrustedByBlock';
import { ContactUsBlock } from '../component/pages/home/contactUsBlock/ContactUsBlock';
import { Featured } from '../component/pages/home/featured/Featured';
import { HomeContent } from '../generated/Home';
import { ProjectHighlight } from '../component/pages/home/projectHighlight/ProjectHighlight';
import { RecentNewsBlockSmart } from '../component/general/recentNewsBlockSmart/RecentNewsBlockSmart';
import { ContactUsBlockContent } from '../generated/ContactUsBlock';

type HomePageProps = {
    homeContent: HomeContent,
    contactUsBlockContent: ContactUsBlockContent,
}

export const HomePage = ({
    homeContent,
    contactUsBlockContent,
}: HomePageProps) => {
    return <>
        <Header {...homeContent.header}/>
        <PotentialMaximise {...homeContent.potentialMaximise}/>
        <HomeLabsPhasesBlock {...homeContent.homeLabsPhasesBlock} />
        <ProjectHighlight {...homeContent.projectHighlight}/>
        <Featured {...homeContent.featured}/>
        <TrustedByBlock {...homeContent.trustedByBlock}/>
        <TrustedByBlock {...homeContent.investedByBlock}/>
        <RecentNewsBlockSmart {...homeContent.recentNewsBlock} />
        <ContactUsBlock {...contactUsBlockContent}/>
    </>;
};
