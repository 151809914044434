import React from 'react';
import { ContactUsContent } from '../generated/ContactUs';
import { Form } from '../component/pages/contactUs/form/Form';
import { Map } from '../component/pages/contactUs/map/Map';
import { Header } from '../component/general/header/Header';

export type ContactUsProps = {
    content: ContactUsContent
}

export const ContactUs = ({
    content
}: ContactUsProps) => {

    return <>

        <Header {...content.header}/>
        <Form {...content.form}/>
        <Map {...content.map}/>

    </>;

};
