import React from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Tags } from '../../../general/tags/Tags';
import { Button } from '../../../controls/button/Button';
import { NewsArticleContent } from '../../../../generated/NewsContent';
import { LayoutComponent } from '../../../general/layoutComponent/LayoutComponent';
import { Picture, PoorText, useContentFromCMSRef } from '2mr-cms-frontend';
import { ShareTarget } from '../../../../generated/NewsHeader';

type Props = NewsArticleContent & {
    backButton?: {
        label?: string,
        url: string,
    },
    shareTargets: ShareTarget[]
}

const DEFAULT_BACK_BUTTON_LABEL = 'Back';

export const Article = (props: Props) => {

    const tags = props.tags.map(t => {
        return {
            label: t,
            url: `/news/${t}`,
        };
    });
    const { ref: ref1 } = useContentFromCMSRef(props.imageUrl);
    const { ref: ref2 } = useContentFromCMSRef(props.author.imageUrl);
    return <>
        <div ref={ref1} className={styles.bgImage} style={imgUrlCssVar(
            '--background-image-url',
            props.imageUrl.value
        )}></div>
        <div className={styles.wrapper}>
            <div className={styles.articleBox}>
                <Tags list={tags}/>
                <h1 className={styles.title}>
                    <PoorText content={props.title}/>
                </h1>
                <hr/>
                <div className={styles.authorContainer}>
                    <div ref={ref2} className={styles.authorImage} style={imgUrlCssVar(
                        '--background-image-url',
                        props.author.imageUrl.value
                    )}/>
                    <div className={styles.authorName}>
                        <PoorText content={props.author.name}/>
                    </div>
                    <div className={styles.authorDate}>
                        <PoorText content={props.date}/> - <PoorText content={props.readingTime}/>
                    </div>
                </div>
                <div className={styles.articleBody}>
                    <LayoutComponent layout={props.body}/>
                </div>
                <div className={styles.controls}>
                    <div className={styles.shareButtons}>
                        {
                            props.shareTargets.map(target =>
                                <Button
                                    style={'air'}
                                    takeAllWidth={false}
                                    isAbsolute={true}
                                    key={target.title.value}
                                    href={
                                        target.urlTemplate.replaceAll(
                                            '${URL}',
                                            encodeURIComponent(window.location.toString())
                                        )
                                    }
                                >
                                    <Picture content={target.icon} className={styles.socialIcon}/>
                                    <PoorText content={target.title}/>
                                    <span className={styles.arrow}></span>
                                </Button>
                            )
                        }
                    </div>
                    {props.backButton ?
                        <Button style={'air'} takeAllWidth={false} href={props.backButton.url}>
                            {props.backButton.label || DEFAULT_BACK_BUTTON_LABEL}
                        </Button> : null
                    }
                </div>
            </div>
        </div>
    </>;
};
