import React from 'react';

import styles from './Header.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { HeaderCommon } from '../../../generated/Common';
import { RichText, useContentFromCMSRef } from '2mr-cms-frontend';

type Props = {
    titleAfterSubtitle: boolean
} & HeaderCommon

export const Header = ({
        title,
        subTitle,
        backgroundUrl,
        titleAfterSubtitle,
    }: Props
) => {

    const { ref } = useContentFromCMSRef(backgroundUrl);
    return <div
        className={styles.header}
        ref={ref}
        style={imgUrlCssVar(
            '--header-background-image-url',
            backgroundUrl.value
        )}
    >

        <div className={styles.content + ' ' + (titleAfterSubtitle ? styles.contentReverse : '')}>
            <div className={styles.title}>
                <RichText
                    content={title}
                    element={'span'}
                />
            </div>
            <div className={styles.subTitle}>
                {subTitle && <RichText content={subTitle} element={'span'}/>}
            </div>
        </div>
    </div>;
};
