import { JsonArrayType, JsonObjectType, oneOf } from '2mr-cms-typegen';
import { HeaderCommon } from './Common';
import { typeDefiner } from '2mr-cms-typegen';

const defineType = typeDefiner('ContactUs.ts');

const FormField: JsonObjectType = {
    title: 'text',
    placeholder: 'text',
    lines: oneOf('oneLine', 'multipleLines')
};

defineType(
    FormField,
    'FormField',
);

const FormLine: JsonArrayType = [FormField];

defineType(
    FormLine,
    'FormLine',
);

const FormContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    lines: [FormLine],
    submitButtonTitle: 'text',
    submittedLabel: 'text',
};

defineType(
    FormContent,
    'FormContent',
);

const MapData: JsonObjectType = {
    title: 'text',
    credentials: [{
        key: 'text',
        value: 'richText'
    }],
    subtitle: 'richText',
    mapFrame: 'richText',
};

defineType(
    MapData,
    'MapData',
);

export const ContactUsContent: JsonObjectType = {
    header: HeaderCommon,
    form: FormContent,
    map: MapData,
};

defineType(
    ContactUsContent,
    'ContactUsContent',
);

export const importContactUsContent = () => {}
