import { JsonArrayType, JsonObjectType, optional } from '2mr-cms-typegen';
import { defineType } from '2mr-cms-typegen';

export const ProjectUrl: JsonObjectType = {
    url: 'url',
    title: 'text'
};

defineType(
    ProjectUrl,
    'ProjectUrl',
    'ProjectsContent.ts'
);

export const ProjectContentDefinition: JsonObjectType = {
    slug: 'string',
    title: 'text',
    tag: 'text',
    description: 'richText',
    imageUrl: 'image',
    body: 'layout',
    projectUrls: [ProjectUrl],
    headerImage: 'image',
    headerTitle: 'text',
    otherProjectsTitle: optional('text')
};

defineType(
    ProjectContentDefinition,
    'ProjectContent',
    'ProjectsContent.ts'
);

export const ProjectPreviewContentDefinition: JsonObjectType = {
    slug: 'string',
    title: 'text',
    tag: 'text',
    description: 'richText',
    headerImage: 'image',
};

defineType(
    ProjectPreviewContentDefinition,
    'ProjectPreviewContent',
    'ProjectsContent.ts'
);

export const ProjectsContent: JsonArrayType = [ProjectContentDefinition];
defineType(
    ProjectsContent,
    'ProjectsContent',
    'ProjectsContent.ts'
);

export const importProjectsContent = () => {
};