import React, { useState } from 'react';
import { FormContent, FormField } from '../../../../generated/ContactUs';

import styles from './Form.scss';
import { Button } from '../../../controls/button/Button';
import { clsx, PoorText, RichText } from '2mr-cms-frontend';

type Values = {
    values: Map<string, string>,
    setValue: (key: string, value: string) => void,
}

const Field = ({
    title,
    placeholder,
    lines,
    values,
    setValue,
}: FormField & Values) => {

    const value = values.get(title.value) || '';
    const setVal = (v: string) => setValue(title.value, v);

    return <div className={styles.formField}>

        {title && <div className={styles.formFieldLabel}>
            <PoorText content={title}/>
        </div>}

        {
            lines === 'oneLine' && <input
                value={value}
                onChange={e => setVal(e.target.value)}
                className={styles.formFieldInput}
                placeholder={placeholder.value}
            />
        }

        {
            lines === 'multipleLines' && <textarea
                onChange={e => setVal(e.target.value)}
                className={clsx(
                    styles.formFieldInput,
                    styles.textArea,
                )}
                placeholder={placeholder.value}
                value={value}
            >
            </textarea>
        }

    </div>;

};

export const Form = ({
    title,
    description,
    lines,
    submitButtonTitle,
    submittedLabel,
}: FormContent) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [values, setValues] = useState(new Map<string, string>());

    const setValue = (key: string, value: string) => {
        const n = new Map(values);
        n.set(key, value);
        setValues(n);
    };

    const submit = () => {
        setIsSubmitting(true);
        fetch('/submitForm', {
            method: 'POST',
            body: JSON.stringify(Object.fromEntries(
                values.entries()
            ))
        })
            .then(() => {
                setIsSubmitted(true);
                setTimeout(() => {
                    document
                        .getElementById('submitted')
                        ?.scrollIntoView({
                            behavior: 'smooth'
                        });
                }, 100);
            });
    };

    return <>
        <div className={styles.form}>
            <div className={styles.formContent}>

                {isSubmitted && <>
                    <div className={styles.submittedLabel} id={'submitted'}>
                        <PoorText content={submittedLabel}/>
                    </div>
                </>}
                {!isSubmitted && <>
                    <div className={styles.formTitleDescriptionBlock}>
                        <div className={styles.formTitle}>
                            <PoorText content={title}/>
                        </div>
                        <div className={styles.formDescription}>
                            <RichText content={description} element={'span'}/>
                        </div>
                    </div>

                    <div className={styles.formLines}>
                        {
                            lines.map((line, i) => <div key={i} className={styles.formLine}>

                                {line.map((field, i) => <Field key={i} {...{
                                    ...{
                                        values,
                                        setValue
                                    },
                                    ...field
                                }}/>)}

                            </div>)
                        }
                        <div className={styles.submit}>
                            {!isSubmitting && <Button
                                style={'air'}
                                onClick={submit}
                            >
                                <PoorText content={submitButtonTitle}/>
                            </Button>}
                        </div>
                    </div>
                </>}


            </div>
        </div>
    </>;
};
