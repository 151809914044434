import styles from './Question.scss';
import React, { useState } from 'react';
import { FAQQuestion } from '../../../../generated/FAQ';
import { LayoutComponent } from '../../../general/layoutComponent/LayoutComponent';
import { clsx, PoorText } from '2mr-cms-frontend';
import { ShownOrHidden } from '../../../../generated/Common';

const DEFAULT_QUESTION_WRAPPED = false;

type FAQQuestionProp = FAQQuestion & {
    defaultState: ShownOrHidden,
}

export const Question = ({
    title,
    description,
    defaultState,
}: FAQQuestionProp) => {

    const [
        isWrapped, setIsWrapped
    ] = useState(defaultState === 'hidden');

    return <div className={clsx(
        styles.question,
        isWrapped && styles.wrapped,
        !isWrapped && styles.unwrapped,
    )}>
        <div
            className={styles.title}
            onClick={() => setIsWrapped(v => !v)}
        >
            <PoorText content={title}/>
        </div>
        <div className={styles.description}>
            <div className={styles.descriptionText}>
                <LayoutComponent layout={description}/>
            </div>
        </div>
    </div>;
};
