import { Block, Layout, PrimitiveBlock } from '../../../generated/Layout';
import styles from './style.scss';
import React from 'react';
import { RichText } from '2mr-cms-frontend';

type LayoutComponentProps = {
    layout: Layout,
}
export const LayoutComponent = (props: LayoutComponentProps) => {

    const blocks = props.layout.map(row => {
        return <div data-layout-row={''} className={styles.rowBlock}><LayoutRowComponent {...row}/></div>;
    });
    return <>
        {blocks}
    </>;
};

export const LayoutRowComponent = (props: Block) => {

    if (props.primitive) {
        return <LayoutPrimitiveBlockComponent {...props}/>;
    }

    const cols = props.content.map(column => {
        return <div data-layout-column={''} className={styles.column}>
            {column.map(elem => {
                return <LayoutPrimitiveBlockComponent {...elem} />;
            })}
        </div>;
    });
    return <>
        {cols}
    </>;
};

export const LayoutPrimitiveBlockComponent = (props: PrimitiveBlock) => {

    if (props.type === 'rich') {
        return <RichText
            data-layout-rich-text={''}
            className={styles.richTextBlock}
            element={'div'}
            content={props.content}
        />;
    }
    if (props.type === 'header') {
        return <h2 data-layout-header={''} className={styles.headerBlock}>{props.content}</h2>;
    }
    if (props.type === 'image') {
        return <img data-layout-image={''} src={props.content} className={styles.imageBlock}/>;
    }
    return null;

};
