import React from "react";
import {KeyObjectiveBlock} from "../../component/pages/solutions/launchpad/keyObjectiveBlock/KeyObjectiveBlock";
import {SolutionsLaunchpadContent, SolutionsTabsContent} from '../../generated/Solutions';
import {
    AccentBlockWithPokingOutImage
} from "../../component/pages/solutions/launchpad/accentBlockWithPokingOutImage/AccentBlockWithPokingOutImage";
import {Header} from "../../component/general/header/Header";
import {Advisory} from "../../component/pages/solutions/launchpad/advisory/Advisory";
import {ThreeColumnGridBlock} from "../../component/general/threeColumnGridBlock/ThreeColumnGridBlock";
import {ContactUsBlock} from "../../component/pages/home/contactUsBlock/ContactUsBlock";
import {SolutionsTabs} from "../../component/pages/solutions/tabs/SolutionsTabs";
import {LaunchProcess} from "../../component/pages/solutions/launchpad/launchProcess/LaunchProcess";
import {ContactUsBlockContent} from '../../generated/ContactUsBlock';
import {OurProjects} from "../../component/general/ourProjects/OurProjects";

interface Props {
    content: SolutionsLaunchpadContent & {
        contactUsBlock: ContactUsBlockContent
        tabs: SolutionsTabsContent
    }
}

export const SolutionsLaunchpadPage = ({content}: Props) => {
    return (
        <>
            <Header {...content.header}/>
            <SolutionsTabs {...content.tabs}/>
            <KeyObjectiveBlock {...content.keyObjectiveContent}/>
            <ThreeColumnGridBlock {...content.launchNftGridContent} />
            <AccentBlockWithPokingOutImage {...content.accentBlockWithPokingOutImage}/>
            <KeyObjectiveBlock {...content.launchpadOperations}/>
            {/* <LaunchProcess {...content.launchProcess}/> */}
            <Advisory {...content.advisoryBlockContent}/>
            <OurProjects {...content.ourProjectsContent}/>
            <ContactUsBlock {...content.contactUsBlock}/>
        </>
    )
}
