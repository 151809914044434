import React from 'react';
import styles from './style.scss';
import { AccentBlockWithPokingOutImageContent } from '../../../../../generated/Solutions';
import { imgUrlCssVar } from '../../../../../utils/CssVar';
import { RichText, useContentFromCMSRef } from '2mr-cms-frontend';

export const AccentBlockWithPokingOutImage = (props: AccentBlockWithPokingOutImageContent) => {

    const { ref } = useContentFromCMSRef(props.imageUrl);
    return <div className={styles.accentBlock}>
        <div className={styles.container}>
            <RichText content={props.text} element={'div'} className={styles.text}/>
            <div className={styles.imageContainer}>
                <div
                    className={styles.image}
                    ref={ref}
                    style={imgUrlCssVar(
                        '--background-image-url',
                        props.imageUrl.value
                    )}></div>

            </div>

        </div>
    </div>;
};
