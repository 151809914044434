import { JsonArrayType, JsonObjectType } from '2mr-cms-typegen';
import { defineType } from '2mr-cms-typegen';

export const NewsArticlePreviewContentDefinition: JsonObjectType = {
    slug: 'string',
    imageUrl: 'image',
    title: 'text',
    tags: ['text'],
    date: 'text',
};


defineType(
    NewsArticlePreviewContentDefinition,
    'NewsArticlePreviewContent',
    'NewsContent.ts'
);

export const NewsArticleContentDefinition: JsonObjectType = {
    slug: 'string',
    imageUrl: 'image',
    title: 'text',
    tags: ['text'],
    date: 'text',

    author: {
        imageUrl: 'image',
        name: 'text',
    },
    readingTime: 'text',
    body: 'layout',
};


defineType(
    NewsArticleContentDefinition,
    'NewsArticleContent',
    'NewsContent.ts'
);

export const NewsContent: JsonArrayType = [NewsArticleContentDefinition];
defineType(
    NewsContent,
    'NewsContent',
    'NewsContent.ts'
);

export const importNewsContent = () => {}
