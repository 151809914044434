import { CompanyContent } from './Company';
import { ContactUsContent } from './ContactUs';
import { FAQContent } from './FAQ';
import { HomeContent } from './Home';
import { FooterContent, NavigationContent } from './Navigation';
import { NewsHeaderContent } from './NewsHeader';
import { NewsContent } from './News';
import { SolutionsContent } from './Solutions';
import { ContactUsBlockContent } from './ContactUsBlock';
import {ProjectsContent} from "./Projects";

export const contentsAndIds = [
    {
        id: 'navigation',
        definition: NavigationContent,
    }, {
        id: 'footer',
        definition: FooterContent,
    }, {
        id: 'home',
        definition: HomeContent,
    }, {
        id: 'contactUsBlock',
        definition: ContactUsBlockContent,
    }, {
        id: 'solutions',
        definition: SolutionsContent,
    }, {
        id: 'company',
        definition: CompanyContent,
    }, {
        id: 'faq',
        definition: FAQContent,
    }, {
        id: 'contactUs',
        definition: ContactUsContent,
    }, {
        id: 'newsHeader',
        definition: NewsHeaderContent,
    }, {
        id: 'news',
        definition: NewsContent,
    }, {
        id: 'projects',
        definition: ProjectsContent
    }
];
