import React from "react";
import styles from "./style.scss";
import {Card} from "../../../../general/card/Card";
import {ThreeColumnCards} from "../../../../general/threeColumnCards/ThreeColumnCards";
import {MergingWorldsContent} from "../../../../../generated/Solutions";
import { PoorText, RichText } from '2mr-cms-frontend';

export const MergingWorldsBlock = ({cards, title, description}: MergingWorldsContent ) => {

    const transformed = cards.map(cardContent => {
        return <Card {...cardContent} />
    })
    return <div className={styles.block}>
        <div className={styles.container}>
            <h2 className={styles.title}><PoorText content={title}/></h2>
            <RichText content={description} element={'div'} className={styles.description}/>
            <ThreeColumnCards cards={transformed}/>
        </div>
    </div>;
};
