import React from 'react';
import styles from './style.scss';
import { Button } from '../../controls/button/Button';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { CardContent } from '../../../generated/Common';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

const DEFAULT_BUTTON_LABEL = 'See More';

export const Card = (props: CardContent) => {
    const { ref } = useContentFromCMSRef(props.image);
    return <div className={styles.card}>
        <div
            className={styles.cardImage}
            ref={ref}
            style={imgUrlCssVar(
                '--background-image-url',
                props.image.value
            )}></div>

        <h3 className={styles.cardTitle}><PoorText content={props.title}/></h3>
        {props.description ?
            <RichText element={'div'} className={styles.cardDescription} content={props.description}/>
            : null
        }
        {props.text ?
            <RichText element={'div'} className={styles.cardText} content={props.text}/> : null
        }
        {props.list ?
            <ul className={styles.cardList}>
                {props.list.map(li => {
                    return <li key={li.value}><PoorText content={li}/></li>;
                })}
            </ul> : null
        }

        {props.buttonLink?.url ?
            <Button href={props.buttonLink.url} takeAllWidth={true} style={'black'}>
                {
                    props.buttonLink.label
                        ? <PoorText content={props.buttonLink.label}/>
                        : DEFAULT_BUTTON_LABEL
                }
            </Button> : null
        }
    </div>;
};

