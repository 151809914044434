import React from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { CompanyGrowthContent, CompanyGrowthContentItem } from '../../../../generated/Company';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

const CompanyGrowthGridItem = (elem: CompanyGrowthContentItem) => {
    const { ref } = useContentFromCMSRef(elem.iconUrl);
    return <li key={elem.bigText.value} className={styles.listElem}>
        <div
            ref={ref}
            className={styles.elemIcon}
            style={imgUrlCssVar(
                '--background-image-url',
                elem.iconUrl.value
            )}></div>

        <div className={styles.elemRight}>
            <RichText className={styles.elemBigText} content={elem.bigText} element={'div'}/>
            <RichText className={styles.elemDescription} content={elem.description} element={'div'}/>
        </div>
    </li>;
};

export const CompanyGrowthGrid = (props: CompanyGrowthContent) => {

    const list1 = props.list.map((elem) => {
        return <CompanyGrowthGridItem {...elem}/>;
    });
    const list2 = props.list2.map((elem) => {
        return <CompanyGrowthGridItem {...elem}/>;
    });

    return <div className={styles.block}>
        <div className={styles.container}>
            <h2 className={styles.title}>
                <PoorText content={props.title}/>
            </h2>
            <RichText className={styles.description} content={props.description} element={'div'}/>
            <h3 style={{ marginBottom: 20 }}>
                <PoorText content={props.list1Heading}/>
            </h3>
            <ul className={styles.list}>
                {list1}
            </ul>
            <h3 style={{ marginTop: 40, marginBottom: 20 }}>
                <PoorText content={props.list2Heading}/>
            </h3>
            <ul className={styles.list}>
                {list2}
            </ul>
        </div>
    </div>;
};
