import { JsonObjectType, optional } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';
import { CardContent, HasTitleAndUrl } from './Common';

const defineType = typeDefiner('Home.ts');

const HomeLabsPhasesBlockContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    cardsContent: [CardContent]
};
defineType(
    HomeLabsPhasesBlockContent,
    'HomeLabsPhasesBlockContent',
);
const PotentialMaximiseContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    imgUrl: 'image',
};
defineType(PotentialMaximiseContent, 'PotentialMaximiseContent',
);
const TrustedByBlockContentPartner: JsonObjectType = {
    imgUrl: 'image',
    url: 'url',
}
defineType(TrustedByBlockContentPartner, 'TrustedByBlockContentPartner',
);
const TrustedByBlockContent: JsonObjectType = {
    title: optional('text'),
    partners: [TrustedByBlockContentPartner],
};
defineType(TrustedByBlockContent, 'TrustedByBlockContent',
);

const RecentNewsBlockContent: JsonObjectType = {
    title: optional(
        'text'),
    buttonLink: {
        label: optional('text'),
        url: 'url',
    }
};
defineType(RecentNewsBlockContent, 'RecentNewsBlockContent');

const ProjectHighlightContent: JsonObjectType = {
    title: 'text'
};
defineType(ProjectHighlightContent, 'ProjectHighlightContent');

const IFeaturedSlide: JsonObjectType = {
    url: 'url',
    titleImage: 'image',
    description: 'text',
    date: 'text',
};
defineType(IFeaturedSlide, 'IFeaturedSlide');
const FeaturedContent: JsonObjectType = {
    title: 'text',
    slides: [IFeaturedSlide]
};
defineType(FeaturedContent, 'FeaturedContent');

const HeaderContent: JsonObjectType = {
    backgroundUrl: 'image',
    superTitle: 'richText',
    title: 'richText',
    subTitle: 'richText',
    button: HasTitleAndUrl,
};
defineType(HeaderContent, 'HeaderContent');

export const HomeContent = {
    header: HeaderContent,
    potentialMaximise: PotentialMaximiseContent,
    homeLabsPhasesBlock: HomeLabsPhasesBlockContent,
    projectHighlight: ProjectHighlightContent,
    featured: FeaturedContent,
    trustedByBlock: TrustedByBlockContent,
    investedByBlock: TrustedByBlockContent,
    recentNewsBlock: RecentNewsBlockContent
};
defineType(HomeContent, 'HomeContent');
export const importHomeContent = () => {
};
