import React from "react";
import styles from './Project.scss';
import {ProjectPageHeader} from "./ProjectPageHeader/ProjectPageHeader";
import {ProjectContent} from "../../../generated/ProjectsContent";
import {ProjectHeader} from "./ProjectHeader/ProjectHeader";
import {ProjectBody} from "./ProjectBody/ProjectBody";
import {ProjectFooter} from "./ProjectFooter/ProjectFooter";
import {OurProjects} from "../../general/ourProjects/OurProjects";

interface Props {
    data: ProjectContent;
}

export const Project = ({data}: Props) => (
    <>
        <ProjectPageHeader
            imgUrl={data.headerImage}
            title={data.headerTitle}
        />
        <div className={styles.project}>
            <ProjectHeader
                tag={data.tag}
                title={data.title}
                imgUrl={data.imageUrl}
                description={data.description}
            />
            <ProjectBody layout={data.body}/>
            <ProjectFooter urls={data.projectUrls}/>
        </div>
        <OurProjects exceptSlug={data.slug} title={data.otherProjectsTitle}/>
    </>
)