import React from "react";
import styles from './Featured.scss';
import {FeaturedSlider} from "./slider/FeaturedSlider";
import {FeaturedContent} from "../../../../generated/Home";
import { PoorText } from '2mr-cms-frontend';

export const Featured = ({title, slides}: FeaturedContent) => {
    if (!slides || slides.length === 0) return null;

    return (
        <div className={styles.featured}>
            <div className={styles.titleWrapper}>
                <h2 className={styles.title}>
                    <PoorText content={title}/>
                </h2>
            </div>
            <FeaturedSlider slides={slides}/>
        </div>
    )
};
