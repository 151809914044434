import React from "react";
import styles from "./style.scss";
import {ThreeColumnCards} from "../../../../general/threeColumnCards/ThreeColumnCards";
import {Card} from "../../../../general/card/Card";
import {KeyObjectiveContent} from "../../../../../generated/Solutions";
import { PoorText, RichText } from '2mr-cms-frontend';

export const KeyObjectiveBlock = (props: KeyObjectiveContent & {theme?: 'default'|'accent'}) => {

    const themeClass= {
        default: styles.default,
        accent: styles.accent,
    }
    const cards = props.cardsContent.map(cardContent => {
        return <div key={cardContent.title.value} className={styles.card}>
                <Card {...cardContent} />
            </div>
    })
    return <div className={styles.wrapper + ' ' + (props.theme ? themeClass[props.theme] : '')}>
        <div className={styles.block}>
            <h2 className={styles.title}>
                <PoorText content={props.title}/>
            </h2>
            {props.subheader?
                <div className={styles.subheader}><PoorText content={props.subheader}/></div> : null
            }

            <RichText content={props.description} element={'div'} className={styles.description}/>
            {/* <ThreeColumnCards cards={cards}/> */}
            <div className={styles.grid}>
                {cards}
            </div>
        </div>
    </div>
};
