import React, {useEffect, useState} from "react";
import {HOME_PAGE_URL} from "../data/Urls";
import {fetchSingleProject} from "../utils/fetch";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "../component/general/loader/Loader";
import {Project} from "../component/pages/project/Project";
import {ProjectContent} from "../generated/ProjectsContent";

export const ProjectPage = () => {
    const [content, setContent] = useState<ProjectContent>()
    const {slug} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!slug) {
            navigate(HOME_PAGE_URL);
            return;
        }
        fetchSingleProject(slug)
            .then((responseArray) => {
                if (responseArray.length < 1) {
                    throw new Error('Project not found: ' + slug)
                }
                return responseArray[0];
            })
            .then(project => {
                setContent(project);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [slug]);

    if (!content) {
        return <Loader/>;
    }

    return <Project data={content}/>
}
