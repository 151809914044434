import React from "react";
import {Header} from "../component/general/header/Header";
import {CompanyContent} from "../generated/Company";
import {StoryGrid} from "../component/pages/company/storyGrid/StoryGrid";
import {CompanyValues} from "../component/pages/company/values/CompanyValues";
import {TeamGrid} from "../component/pages/company/teamGrid/TeamGrid";
import {CompanyGrowthGrid} from "../component/pages/company/growth/Growth";
import {TwoColumnCard} from "../component/general/twoColumnCard/TwoColumnCard";

interface Props {
    content: CompanyContent,
}

export const CompanyPage = ({content}: Props) => {
    return (
        <>
            <Header {...content.header}/>
            <TwoColumnCard {...content.ourMission}/>
            <CompanyValues {...content.values}/>
            <StoryGrid {...content.story}/>
            <CompanyGrowthGrid {...content.growth} />
            <TeamGrid {...content.team}/>
            <TeamGrid {...content.advisory}/>
        </>
    )
}
