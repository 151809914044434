import React, { useMemo } from 'react';
import styles from './SolutionsTabs.scss';
import { SolutionsTabsContent } from '../../../../generated/Solutions';
import { Link, useLocation } from 'react-router-dom';
import { TwoColumnCard } from '../../../general/twoColumnCard/TwoColumnCard';
import { PoorTextContent } from '../../../../generated/StdLib';
import { clsx, PoorText } from '2mr-cms-frontend';

export const SolutionsTabs = ({ tabs }: SolutionsTabsContent) => {
    const location = useLocation();

    const activeIndex = useMemo(() => {
        if (location.hash.length === 0) {
            return 0;
        }
        return tabs.findIndex(tab => tab.url === location.pathname + location.hash);
    }, [tabs, location.hash]);

    return (
        <div className={styles.solutionsTabs}>
            <div className={styles.tabs}>
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        title={tab.title}
                        url={tab.url}
                        active={index === activeIndex}
                    />
                ))}
            </div>
            {activeIndex >= 0 && (
                <TwoColumnCard {...tabs[activeIndex].description}/>
            )}
        </div>
    );
};

export interface TabProps {
    title: PoorTextContent | string,
    url: string,
    active: boolean
}

export const Tab = ({
    title,
    url,
    active
}: TabProps) => (
    <Link to={url} className={clsx(styles.tab, active && styles.tabActive)}>
        {typeof title === 'string' ? title : <PoorText content={title}/>}
    </Link>
);
