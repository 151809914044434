import React from "react";
import styles from "./style.scss";
import {CompanyTeamContent} from "../../../../generated/Company";
import {PersonCard} from "../../../general/personCard/PersonCard";
import { PoorText, RichText } from '2mr-cms-frontend';


export const TeamGrid = (props: CompanyTeamContent) => {

    const elems = props.personCards.map((elem, i) => {
        return <li key={i} className={styles.card} >
            <PersonCard {...elem}/>
        </li>
    })
    return (
        <div className={styles.block}>
            <div className={styles.container}>
                <h2 className={styles.title}>
                    <PoorText content={props.title}/>
                </h2>
                {props.description?
                    <RichText content={props.description} element={'div'} className={styles.description}/>
                    : null
                }

                <ul className={styles.grid}>
                    {elems}
                </ul>
            </div>
        </div>
    )
};

