import React from 'react';
import styles from './ProjectHighlightSlide.scss';
import { Button } from '../../../../../controls/button/Button';
import { ProjectPreviewContent } from '../../../../../../generated/ProjectsContent';
import { PROJECT_BASE_URL } from '../../../../../../data/Urls';
import { clsx, PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

interface Props {
    data: ProjectPreviewContent;
    className?: string;
}

export const ProjectHighlightSlide = ({
    data,
    className
}: Props) => {
    const { ref } = useContentFromCMSRef(data.headerImage);
    return (
        <div className={clsx(styles.projectSlide, className)}>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.contentTop}>
                        <h2 className={styles.title}><PoorText content={data.title}/></h2>
                        <h4 className={styles.subTitle}><PoorText content={data.tag}/></h4>
                        <RichText content={data.description} element="p" className={styles.description}/>
                    </div>
                    <Button style="air" href={`${PROJECT_BASE_URL}${data.slug}`}>
                        Learn More
                    </Button>
                </div>
            </div>
            <div className={styles.imageWrapperWrapperWrapper}>
                <div className={styles.imageWrapperWrapper}>
                    <div className={styles.imageWrapper}>
                        <img
                            ref={ref}
                            src={data.headerImage.value}
                            alt="Project Cover"
                            className={styles.image}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
};
