import React, {useState} from "react";
import styles from './ProjectHighlightSlider.scss';
import {DEFAULT_SLIDER_STATE, Slider, SliderState, useSliderControls} from "../../../../general/slider/Slider";
import {SwiperSlide} from "swiper/react";
import {ProjectHighlightSlide} from "./slide/ProjectHighlightSlide";
import {SliderPagination} from "../../../../general/slider/pagination/SliderPagination";
import {SliderNavigation} from "../../../../general/slider/navigation/SliderNavigation";
import {ProjectPreviewContent} from "../../../../../generated/ProjectsContent";
import { clsx } from '2mr-cms-frontend';

interface Props {
    slides: ProjectPreviewContent[];
    className?: string;
}

export const ProjectHighlightSlider = ({slides, className}: Props) => {
    const [state, setState] = useState<SliderState>(DEFAULT_SLIDER_STATE);
    const {handlePaginationClick, handleLeftClick, handleRightClick} = useSliderControls(state.ref);

    return (
        <div className={clsx(styles.projectSlider, className)}>
            <Slider setState={setState} slidesPerView={1}>
                {slides.map((slide, index) => (
                    <SwiperSlide key={`ph-slide-${index}`} className={styles.slide}>
                        <ProjectHighlightSlide data={slide}/>
                    </SwiperSlide>
                ))}
            </Slider>
            {state.pagination.total > 1 && (
                <>
                    <div className={styles.paginationWrapper}>
                        <SliderPagination
                            current={state.pagination.current}
                            total={state.pagination.total}
                            onClick={(index) => handlePaginationClick(index)}
                            className={styles.pagination}
                        />
                    </div>
                    <div className={styles.navigationWrapperWrapper}>
                        <div className={styles.navigationWrapper}>
                            <SliderNavigation
                                color='blue'
                                leftDisabled={state.navigation.left}
                                rightDisabled={state.navigation.right}
                                onLeftClick={handleLeftClick}
                                onRightClick={handleRightClick}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}