import React from 'react';

import styles from './Footer.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { NavLink } from '../navigation/navLink/NavLink';
import { SocialNetworks } from '../socialNetworks/SocialNetworks';
import { FooterContent, Location, NavigationContent, Section } from '../../../generated/Navigation';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

const Section = ({
    url,
    title,
    subsections
}: Section) => {
    return <div className={styles.sectionBlock}>
        <NavLink
            type={'primary'}
            title={title}
            href={url}
        />
        {
            subsections.map((s, i) =>
                <NavLink
                    type={'secondary'}
                    title={s.title}
                    href={s.url}
                    key={i}
                />
            )
        }
    </div>;
};

const Location = ({
    country,
    address,
    email
}: Location) => {
    return <div className={styles.location}>
        <div className={styles.country}><PoorText content={country}/></div>
        <div className={styles.address}>
            <RichText content={address} element={'span'}/>
        </div>
        <a className={styles.email} href={'mailto:' + email.value}><PoorText content={email}/></a>
    </div>;
};

export const Footer = ({
    companyName,
    fullLogoUrl,
    locations,
    socials,
    sections,
    /*contact,*/
}: FooterContent & NavigationContent) => {
    const {
        ref,
    } = useContentFromCMSRef(fullLogoUrl);
    return <div className={styles.footer}>

        <div className={styles.content}>

            <div className={styles.grid}>

                <div
                    className={styles.logo}
                    ref={ref}
                    style={imgUrlCssVar(
                        '--footer-logo-url',
                        fullLogoUrl.value,
                    )}
                />
                <div className={styles.nav}>
                    {sections.map((s, i) => <Section {...s} key={i}/>)}
                </div>
                {/*<div className={styles.contact}>
                    <Section {...contact}/>
                </div>*/}
                <div className={styles.locations}>
                    {locations.map((l, i) => <Location {...l} key={i}/>)}
                </div>
                <div className={styles.socials}>
                    <SocialNetworks
                        socials={socials}
                        color={'white'}
                    />
                </div>
                <div className={styles.copyright}>
                    © {new Date().getFullYear()} <PoorText content={companyName}/>
                </div>

            </div>

        </div>

    </div>;
};
