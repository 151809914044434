import { FAQChapter } from '../../../../generated/FAQ';
import React, { useState } from 'react';
import { QuestionsChaptersSelector } from '../questionsChaptersSelector/QuestionsChaptersSelector';
import { Question } from '../question/Question';

import styles from './Questions.scss';
import { useLocation } from 'react-router-dom';
import { QuestionsChapter } from '../questionsChapter/QuestionsChapter';
import { ShownOrHidden } from '../../../../generated/Common';

type QuestionsProps = {
    chapters: FAQChapter[],
    defaultState: ShownOrHidden,
}

export const Questions = ({
    chapters,
    defaultState,
}: QuestionsProps) => {

    const location = useLocation();
    const hash = location.hash;

    const selectedChapter = decodeURIComponent(hash) || '#' + chapters?.at(0)?.title || '';

    return (
        <div className={styles.questions}>
            <div className={styles.toc}>
                <QuestionsChaptersSelector
                    chapters={chapters}
                    selectedChapter={selectedChapter}
                />
            </div>
            <div className={styles.content}>
                {
                    chapters.map((chapter, i) =>
                        <QuestionsChapter
                            key={i}
                            chapter={chapter}
                            selectedChapter={selectedChapter}
                            defaultState={defaultState}
                        />
                    )
                }
            </div>
        </div>
    );

};
