import React, { useEffect, useState } from 'react';

import { Footer } from './component/general/footer/Footer';
import { NavPanel } from './component/general/navigation/navPanel/NavPanel';
import { NavMobile } from './component/general/navigation/navMobile/NavMobile';
import { Loader } from './component/general/loader/Loader';
import { Router } from './Router';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { HomeContent } from './generated/Home';
import { ContactUsBlockContent } from './generated/ContactUsBlock';
import { SolutionsContent } from './generated/Solutions';
import { CompanyContent } from './generated/Company';
import { FAQContent } from './generated/FAQ';
import { ContactUsContent } from './generated/ContactUs';
import { NewsHeaderContent } from './generated/NewsHeader';
import { FooterContent, NavigationContent } from './generated/Navigation';
import { contentsAndIds } from '../../common/src/types/ContentsAndIds';
import { ContentFromCMSTooltip, prepareContent } from '2mr-cms-frontend';

type Content = {
    homeContent: HomeContent,
    contactUsBlockContent: ContactUsBlockContent,
    solutionsContent: SolutionsContent,
    companyContent: CompanyContent,
    faqContent: FAQContent,
    contactUsContent: ContactUsContent,
    newsHeaderContent: NewsHeaderContent,
    navigationContent: NavigationContent,
    footerContent: FooterContent,
}

const loadContent = async () => {
    return Object.fromEntries(await Promise.all(
        contentsAndIds.map(({
                id,
                definition
            }) =>
                fetch(`/content?id=${id}&enrich=1`)
                    .then((v) => v.json())
                    .then((json) => [id + 'Content', prepareContent(
                        json,
                        id,
                        '',
                        definition
                    )])
        )));
};
const initiateGA = () => {
    try {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            // @ts-ignore
            dataLayer.push(arguments);
        }
        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', 'G-51ZFQQDZC0');
    } catch (err) {
        console.log("Can't initiate GA")
    }
}

export const App = () => {

    const [content, setContent] = useState<Content | undefined>(undefined);

    useEffect(() => {
        loadContent()
            .then(content => {
                setContent(content as Content);
            });
        const domain = window.location.hostname || window.location.host;
        if(!domain.includes('localhost')) initiateGA();
    }, []);

    const [
        isMobileNavOpen,
        setIsMobileNavOpen
    ] = useState(false);

    const closeMobileNav = () => {
        setIsMobileNavOpen(false);
    };

    const openMobileNav = () => {
        setIsMobileNavOpen(true);
    };

    if (!content) {
        return <Loader/>;
    }

    return <BrowserRouter>
        <ContentFromCMSTooltip/>
        <ScrollToTop/>
        <NavPanel {...{
            ...{ onOpenMenu: openMobileNav },
            ...content.navigationContent
        }}/>
        <NavMobile {...{
            ...{
                opened: isMobileNavOpen,
                onClose: closeMobileNav,
            },
            ...content.footerContent,
            ...content.navigationContent
        }}/>
        <Router {...content}/>
        <Footer {...{ ...content.footerContent, ...content.navigationContent }}/>
    </BrowserRouter>;
};

export function ScrollToTop () {
    const {
        pathname,
        hash
    } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, hash]);

    return null;
}
