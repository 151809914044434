import { HasTitleAndUrl, SocialNetwork } from './Common';
import { JsonObjectType } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';

const defineType = typeDefiner('Navigation.ts');

export const Section: JsonObjectType = {
    ...HasTitleAndUrl,
    subsections: [HasTitleAndUrl]
};
defineType(
    Section,
    'Section',
);

export const NavigationContent: JsonObjectType = {
    smallLogoUrl: 'image',
    closeIconUrl: 'image',
    menuIconUrl: 'image',
    sections: [Section],
    contact: Section,
};
defineType(
    NavigationContent,
    'NavigationContent',
);

export const Location: JsonObjectType = {
    country: 'text',
    address: 'text',
    email: 'text',
};
defineType(
    Location,
    'Location',
);

export const FooterContent: JsonObjectType = {
    companyName: 'text',
    fullLogoUrl: 'image',
    locations: [Location],
    socials: [SocialNetwork],
};
defineType(
    FooterContent,
    'FooterContent',
);

export const importNavigationContent = () => {
};
