import React from 'react';

import styles from './PotentialMaximise.scss';
import { PotentialMaximiseContent } from '../../../../generated/Home';
import { clsx, PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

export const PotentialMaximise = ({
    title,
    description,
    imgUrl
}: PotentialMaximiseContent) => {
    const { ref } = useContentFromCMSRef(imgUrl);
    return (
        <div className={styles.potentialMaximise}>
            <div className={styles.content}>
                <h1 className={clsx(styles.textTitleTablet, styles.textTitle)}>
                    <PoorText content={title}/>
                </h1>
                <div className={styles.horizontalContent}>
                    <div className={styles.text}>
                        <h1 className={clsx(styles.textTitle, styles.textTitlePC)}>
                            <PoorText content={title}/>
                        </h1>
                        <RichText content={description} element="p" className={styles.textDescription}/>
                    </div>
                    <div className={styles.imageWrapper}>

                        <img
                            ref={ref}
                            src={imgUrl.value}
                            alt="globe"
                            className={styles.image}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
