import { JsonObjectType, oneOf } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';
import { HeaderCommon, ShownOrHidden } from './Common';

const defineType = typeDefiner('FAQ.ts');

const FAQQuestion: JsonObjectType = {
    title: 'text',
    description: 'layout',
};
defineType(FAQQuestion, 'FAQQuestion');

const FAQChapter: JsonObjectType = {
    title: 'text',
    content: [FAQQuestion],
};
defineType(FAQChapter, 'FAQChapter');

export const FAQContent: JsonObjectType = {
    header: HeaderCommon,
    chapters: [FAQChapter],
    defaultAnswersState: ShownOrHidden
};
defineType(FAQContent, 'FAQContent');

export const importFaqContent = () => {
};
