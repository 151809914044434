export const HOME_PAGE_URL = "/";
export const SOLUTIONS_PAGE_URL = "/solutions";
export const COMPANY_PAGE_URL = "/company";
export const FAQ_PAGE_URL = "/faq";
export const CONTACT_US_PAGE_URL = "/contactus";
export const NEWS_URL = "/news";
export const NEWS_FILTER_URL = "/news/:tag";
export const NEWS_SINGLE_BASE_URL = "/post/";
export const NEWS_SINGLE_URL = NEWS_SINGLE_BASE_URL +':slug';
export const PROJECT_BASE_URL = "/projects/"
export const PROJECT_URL = PROJECT_BASE_URL + ":slug";
