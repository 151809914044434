import React from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { TrustedByBlockContent, TrustedByBlockContentPartner } from '../../../../generated/Home';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

const TrustedByBlockCard = (item: TrustedByBlockContentPartner) => {
    const { ref } = useContentFromCMSRef(item.imgUrl);
    return (
        <li className={styles.card}>
            <a href={item.url}
               className={styles.cardLink}
               ref={ref}
               style={
                   imgUrlCssVar(
                       '--background-image-url',
                       item.imgUrl.value
                   )
               }
               target="_blank"
            >
                <span className={styles.cardImage}></span>
            </a>

        </li>
    );
};

export const TrustedByBlock = (props: TrustedByBlockContent) => {
    const cards = props.partners.map(item =>
        <TrustedByBlockCard key={item.imgUrl.value}  {...item}/>
    );
    return (
        <div className={styles.trustedByBlock}>
            <div className={styles.block}>
                <h2 className={styles.title}>
                    <PoorText content={props.title}/>
                </h2>
                <ul className={styles.list}>
                    {cards}
                </ul>
            </div>
        </div>
    );
};

