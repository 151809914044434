import React from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { SimpleCardContent } from '../../../generated/Common';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

export const SimpleCard = (props: SimpleCardContent) => {
    const { ref } = useContentFromCMSRef(props.imageUrl);

    return <div className={styles.card}>

        <div
            ref={ref}
            className={styles.cardImage}
            style={imgUrlCssVar(
                '--background-image-url',
                props.imageUrl.value
            )}></div>
        {props.title ?
            <h3 className={styles.cardTitle}><PoorText content={props.title}/></h3>
            : null
        }
        {props.description ?
            <RichText element={'div'} className={styles.cardDescription} content={props.description}/>
            : null
        }

    </div>;
};

