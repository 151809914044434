import React from "react";
import {SolutionsMetaverseContent, SolutionsTabsContent} from '../../generated/Solutions';
import {Header} from "../../component/general/header/Header";
import {
    ShapeTheFuture
} from "../../component/pages/solutions/metaverse/shapeTheFutureBlock/ShapeTheFutureBlock";
import {
    MergingWorldsBlock
} from "../../component/pages/solutions/metaverse/mergingWorldsBlock/MergingWorldsBlock";
import {ContactUsBlock} from "../../component/pages/home/contactUsBlock/ContactUsBlock";
import {SolutionsTabs} from "../../component/pages/solutions/tabs/SolutionsTabs";
import {ThreeColumnGridBlock} from "../../component/general/threeColumnGridBlock/ThreeColumnGridBlock";
import { ContactUsBlockContent } from '../../generated/ContactUsBlock';

interface Props {
    content: SolutionsMetaverseContent & {
        contactUsBlock: ContactUsBlockContent,
        tabs: SolutionsTabsContent
    }
}

export const SolutionsMetaversePage = ({content}: Props) => {
    return (
        <>
            <Header {...content.header}/>
            <SolutionsTabs {...content.tabs}/>
            <MergingWorldsBlock {...content.mergingWorlds}/>
            <ShapeTheFuture {...content.shapeTheFuture}/>
            <ThreeColumnGridBlock {...content.benefitsToBrands} />
            <ContactUsBlock {...content.contactUsBlock}/>
        </>
    )
}
