import styles from './TwoColumnCard.scss';
import React from 'react';
import { TwoColumnsCardContent } from '../../../generated/Common';
import { clsx, PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

export const TwoColumnCard = ({
    title,
    description,
    imageUrl,
    dark
}: TwoColumnsCardContent) => {
    const { ref } = useContentFromCMSRef(imageUrl);
    return (
        <div className={clsx(styles.twoColumns, dark && styles.twoColumnsDark)}>
            <div className={styles.description}>
                <div className={styles.content}>
                    <h2 className={styles.title}><PoorText content={title}/></h2>
                    <RichText content={description} element="p" className={styles.contentDesc}/>
                </div>
                <div className={styles.imageWrapper}>
                    <img ref={ref} src={imageUrl.value} alt="Image" className={styles.image}/>

                </div>
            </div>
        </div>
    );
};