import React, { useEffect, useState } from 'react';
import { NEWS_URL } from '../../../data/Urls';
import { RecentNewsBlockContent } from '../../../generated/Home';
import { RecentNewsBlock } from '../recentNewsBlock/RecentNewsBlock';
import { NewsArticlePreviewContent } from '../../../generated/NewsContent';
import { Button } from '../../controls/button/Button';
import { fetchNewsList } from '../../../utils/fetch';
import { useParams } from 'react-router-dom';
import { PoorText } from '2mr-cms-frontend';

const DEFAULT_PAGE_SIZE = 4;
type Props = RecentNewsBlockContent & {
    hideBtn?: boolean,
};
export const RecentNewsBlockSmart = (props: Props) => {
    const [list, setList] = useState<NewsArticlePreviewContent[]>([]);
    const { slug } = useParams();

    useEffect(() => {
        fetchNewsList(0, DEFAULT_PAGE_SIZE + 1).then((r: NewsArticlePreviewContent[]) => {
            const filteredNews = r.filter(n => {
                return n.slug !== slug;
            }).slice(0, DEFAULT_PAGE_SIZE);
            setList(filteredNews);
        }).catch((e) => {
            console.error(e);
        });
    }, [slug]);

    const btn = props.hideBtn ? null : <Button href={props.buttonLink.url || NEWS_URL} style={'air'}>
        {
            props.buttonLink.label
                ? <PoorText content={props.buttonLink.label}/>
                : 'See All News'
        }
    </Button>;
    return (<RecentNewsBlock title={props.title} newsList={list} buttonLink={btn}/>);
};
