import React from "react";
import {ThreeColumnGridBlockContent} from "../../../generated/Common";
import styles from "./style.scss";
import {SimpleCard} from "../simpleCard/SimpleCard";
import { PoorText, RichText } from '2mr-cms-frontend';


/* export const defaultThreeColumnGridBlockProps: ThreeColumnGridBlockContent = {
    title: 'Benefits to Brands on Reality x Metaverse Platform',
    description: '2MR Planet combines the Metaverse with reality, where users take part in entertaining activities in both online and offline, promoting an active lifestyle in an interactive way while reaping benefits.',
    cards: [{
        title: 'One-Stop Solution Provider',
        description: 'Develop curated solutions to suit your business goals, from ideation to execution.',
        imageUrl: 'lightbulb.png',
    }, {
        title: undefined,
        description: '<b>Improve brand recognition through immersive and innovative marketing</b>',
        imageUrl: 'lightbulb.png'
    }, {
        title: 'One-Stop Solution Provider',
        description: 'Develop curated solutions to suit your business goals, from ideation to execution.',
        imageUrl: 'lightbulb.png',
    }, {
        title: 'Improve brand recognition through immersive and innovative marketing',
        description: undefined,
        imageUrl: 'lightbulb.png'
    }, {
        title: 'One-Stop Solution Provider',
        description: 'Develop curated solutions to suit your business goals, from ideation to execution.',
        imageUrl: 'lightbulb.png',
    }, {
        title: 'Improve brand recognition through immersive and innovative marketing',
        description: undefined,
        imageUrl: 'lightbulb.png'
    }]
} */

export const ThreeColumnGridBlock = (props: ThreeColumnGridBlockContent) => {

    const elems = props.cards.map((card, i) => {
        return <li className={styles.card} key={i}>
            <SimpleCard {...card}/>
        </li>
    })
    return (
        <div className={styles.block}>
            <div className={styles.container}>
                <h2 className={styles.title}>
                    <PoorText content={props.title}/>
                </h2>
                <RichText content={props.description} element={'div'} className={styles.description}/>
                <ul className={styles.grid}>
                    {elems}
                </ul>
            </div>
        </div>
    )

};
