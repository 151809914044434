import React from "react";

import styles from "./Button.scss";
import {Link} from "react-router-dom";
import { clsx } from '2mr-cms-frontend';

export type ButtonProps = {
    children: React.ReactNode,
    takeAllWidth?: boolean,
    className?: string,
    style: "filled" | "air" | "black",
} & (
    | { href: string, isAbsolute?: boolean }
    | { onClick: () => void, disabled?: boolean }
    )

export const Button = (props: ButtonProps) => {

    const {
        children, takeAllWidth, style, className: externalClassName
    } = props;

    const className = clsx(
        styles.button,
        takeAllWidth && styles.takeAllWidth,
        style === "filled" && styles.filled,
        style === "air" && styles.air,
        style === "black" && styles.black,
        externalClassName,
    )

    if ("href" in props) {
        if(props.isAbsolute || props.href.indexOf('mailto:') === 0){
            return <a className={className} href={props.href}>
                {children}
            </a>
        }
        return <Link className={className} to={props.href}>
            {children}
        </Link>
    } else {
        return <button className={className} onClick={props.onClick} disabled={props.disabled}>
            {children}
        </button>
    }

}
