import {Route, Routes} from 'react-router-dom';
import React from 'react';
import {
    COMPANY_PAGE_URL,
    CONTACT_US_PAGE_URL,
    FAQ_PAGE_URL,
    NEWS_URL,
    HOME_PAGE_URL,
    NEWS_FILTER_URL,
    NEWS_SINGLE_URL,
    SOLUTIONS_PAGE_URL, PROJECT_URL
} from './data/Urls';
import {HomePage} from './page/Home';
import {FAQ} from './page/FAQ';
import {CompanyPage} from './page/Company';
import {ContactUs} from './page/ContactUs';
import {SolutionsPage} from './page/solutions';
import {NewsSinglePage} from './page/NewsSinglePage';
import {NewsPage} from './page/NewsPage';
import {HomeContent} from './generated/Home';
import {ContactUsBlockContent} from './generated/ContactUsBlock';
import {SolutionsContent} from './generated/Solutions';
import {CompanyContent} from './generated/Company';
import {FAQContent} from './generated/FAQ';
import {ContactUsContent} from './generated/ContactUs';
import {NewsHeaderContent} from './generated/NewsHeader';
import {ProjectPage} from "./page/ProjectPage";

export type RouterProps = {
    homeContent: HomeContent,
    contactUsBlockContent: ContactUsBlockContent,
    solutionsContent: SolutionsContent,
    companyContent: CompanyContent,
    faqContent: FAQContent,
    contactUsContent: ContactUsContent,
    newsHeaderContent: NewsHeaderContent,
}

export const Router = ({
    homeContent,
    contactUsBlockContent,
    solutionsContent,
    companyContent,
    faqContent,
    contactUsContent,
    newsHeaderContent,
}: RouterProps) => {
    return <Routes>
        <Route
            path={HOME_PAGE_URL}
            element={<HomePage
                homeContent={homeContent}
                contactUsBlockContent={contactUsBlockContent}
            />}
        />
        <Route
            path={SOLUTIONS_PAGE_URL}
            element={<SolutionsPage
                solutionsContent={solutionsContent}
                contactUsBlockContent={contactUsBlockContent}
            />}
        />
        <Route
            path={COMPANY_PAGE_URL}
            element={<CompanyPage content={companyContent}/>}
        />
        <Route
            path={FAQ_PAGE_URL}
            element={<FAQ content={faqContent}/>}
        />
        <Route
            path={CONTACT_US_PAGE_URL}
            element={<ContactUs content={contactUsContent}/>}
        />

        <Route
            path={NEWS_FILTER_URL}
            element={<NewsPage content={newsHeaderContent}/>}
        />
        <Route
            path={NEWS_URL}
            element={<NewsPage content={newsHeaderContent}/>}
        />
        <Route
            path={NEWS_SINGLE_URL}
            element={<NewsSinglePage content={newsHeaderContent}/>}
        />
        <Route
            path={PROJECT_URL}
            element={<ProjectPage />}
        />

        <Route
            path={'*'}
            element={<HomePage
                homeContent={homeContent}
                contactUsBlockContent={contactUsBlockContent}
            />}
        />

    </Routes>;
};
