import styles from './NavMobile.scss';
import React from 'react';
import { SocialNetworks } from '../../socialNetworks/SocialNetworks';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Link, useLocation } from 'react-router-dom';
import { FooterContent, NavigationContent } from '../../../../generated/Navigation';
import { HOME_PAGE_URL } from '../../../../data/Urls';
import { clsx, PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export type NavMobileProps = NavigationContent & FooterContent & {
    opened: boolean,
    onClose: () => void,
}

export const NavMobile = ({
    sections,
    contact,
    socials,
    smallLogoUrl,
    closeIconUrl,
    companyName,
    opened,
    onClose,
}: NavMobileProps) => {

    const location = useLocation();

    const {
        ref: ref1,
    } = useContentFromCMSRef(smallLogoUrl);
    const {
        ref: ref2,
    } = useContentFromCMSRef(closeIconUrl);

    return <div className={clsx(
        styles.navMobile,
        opened && styles.opened,
        !opened && styles.closed,
    )}>

        <div
            className={styles.header}
        >
            <Link
                to={HOME_PAGE_URL}
                className={styles.logo}
                ref={ref1}
                style={imgUrlCssVar(
                    '--nav-mobile-logo',
                    smallLogoUrl.value,
                )}
            />
            <button
                className={styles.close}
                onClick={onClose}
                ref={ref2}
                style={imgUrlCssVar(
                    '--nav-mobile-close-icon',
                    closeIconUrl.value,
                )}
            />
        </div>
        <div className={styles.nav}>
            {[...sections, contact].map((s, i) =>
                <div
                    className={styles.sectionBlock}
                    key={i}
                >
                    {s.url.indexOf('mailto:') === 0 ? (
                        <a
                            href={s.url}
                            onClick={onClose}
                            className={clsx(
                                styles.section,
                                s.url === location.pathname && styles.active,
                            )}>
                            <PoorText content={s.title}/>
                        </a>
                    ) : (
                        <Link
                            to={s.url}
                            onClick={onClose}
                            className={clsx(
                                styles.section,
                                s.url === location.pathname && styles.active,
                            )}>
                            <PoorText content={s.title}/>
                        </Link>
                    )}
                    {
                        s.subsections.map((s, i) =>
                            <Link
                                to={s.url}
                                onClick={onClose}
                                className={clsx(
                                    styles.subSection,
                                    s.url === location.pathname && styles.active,
                                )}
                                key={i}
                            >
                                <PoorText content={s.title}/>
                            </Link>
                        )
                    }
                </div>)}
        </div>
        <div className={styles.footer}>
            <SocialNetworks
                socials={socials}
                color={'black'}
            />
            <div className={styles.copyright}>
                © {new Date().getFullYear()} <PoorText content={companyName}/>
            </div>
        </div>

    </div>;
};
