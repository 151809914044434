import React from "react";
import styles from "./style.scss";
import {ThreeColumnCards} from "../../../general/threeColumnCards/ThreeColumnCards";
import {Card} from "../../../general/card/Card";
import { HomeLabsPhasesBlockContent } from "../../../../generated/Home";
import { PoorText, RichText } from '2mr-cms-frontend';

export const HomeLabsPhasesBlock = (props: HomeLabsPhasesBlockContent) => {


    const cards = props.cardsContent.map(cardContent => {
        return <Card {...cardContent} />
    })
    return <div className={styles.block}>
        <div className={styles.blueBox}>
            <div className={styles.blueBoxContainer}>
            <h2 className={styles.title}>
                <PoorText content={props.title}/>
            </h2>
                <RichText content={props.description} element={'div'} className={styles.description}/>
            </div>
        </div>
        <div className={styles.cardsBox}>
            <div className={styles.cardsBoxContainer}>
                <ThreeColumnCards cards={cards}/>
            </div>
        </div>
    </div>;
};
