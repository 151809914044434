import { JsonObjectType } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';
import { HeaderCommon } from './Common';

const defineType = typeDefiner('NewsHeader.ts');

export const ShareTarget: JsonObjectType = {
    icon: 'image',
    title: 'text',
    urlTemplate: 'string',
}
defineType(ShareTarget, 'ShareTarget');

export const NewsHeaderContent: JsonObjectType = {
    header: HeaderCommon,
    filterTags: ['text'],
    loadMoreButtonLabel: 'text',
    readNextHeader: 'text',
    shareTargets: [ShareTarget]
};
defineType(NewsHeaderContent, 'NewsHeaderContent');

export const importNewsHeaderContent = () => {
};
