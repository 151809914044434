import React from "react";
import styles from './ProjectBody.scss';
import {Layout} from "../../../../generated/Layout";
import {LayoutComponent} from "../../../general/layoutComponent/LayoutComponent";

interface Props {
    layout: Layout;
}

export const ProjectBody = ({layout}: Props) => (
    <div className={styles.projectBody}>
        <LayoutComponent layout={layout}/>
    </div>
)