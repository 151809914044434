import React from 'react';

import styles from './QuestionsChaptersSelector.scss';
import { FAQChapter } from '../../../../generated/FAQ';
import { clsx, PoorText } from '2mr-cms-frontend';

type QuestionsGroupProps = {
    chapters: FAQChapter[],
    selectedChapter: string,
}

export const QuestionsChaptersSelector = ({
    chapters,
    selectedChapter,
}: QuestionsGroupProps) => {
    return <div className={styles.questionsChaptersSelector}>
        {
            chapters.map((c, i) =>
                <a
                    key={i}
                    className={clsx(
                        styles.group,
                        selectedChapter === '#' + c.title && styles.selected
                    )}
                    href={'#' + encodeURIComponent(c.title.value)}
                >
                    <PoorText content={c.title}/>
                </a>
            )
        }
    </div>;
};
