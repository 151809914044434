import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "./App";
import { initReact } from '2mr-cms-frontend';

initReact(React)

const container = document.getElementById("root");
if (container === null) {
    throw new Error("No root container found, failed to start app");
}

const root = ReactDOM.createRoot(container);
root.render(<App/>);
