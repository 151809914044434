import styles from './SocialNetworks.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import React from 'react';
import { SocialNetwork } from '../../../generated/Common';
import { clsx, useContentFromCMSRef } from '2mr-cms-frontend';

export type SocialNetworksProps = {
    socials: SocialNetwork[],
    color: 'black' | 'gray' | 'white',
}

export const Social = ({
    url,
    logoUrl
}: SocialNetwork) => {
    const { ref } = useContentFromCMSRef(logoUrl);
    return <>
        <a
            ref={ref}
            href={url}
            className={styles.socialNetwork}
            style={imgUrlCssVar(
                '--social-network-logo-url',
                logoUrl.value
            )}
        />
        
    </>;
};

export const SocialNetworks = ({
    socials,
    color
}: SocialNetworksProps) => {
    return <div className={clsx(
        styles.socialNetworks,
        color === 'white' && styles.white,
        color === 'gray' && styles.gray,
        color === 'black' && styles.black,
    )}>
        {socials.map((s, i) => <Social key={i} {...s}/>)}
    </div>;
};
