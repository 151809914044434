import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../component/general/header/Header';
import { RecentNewsBlock } from '../component/general/recentNewsBlock/RecentNewsBlock';
import { NewsTabs } from '../component/pages/news/newsTabs/NewsTabs';
import { NewsArticlePreviewContent } from '../generated/NewsContent';
import { Button } from '../component/controls/button/Button';
import { fetchNewsList } from '../utils/fetch';
import { NEWS_URL } from '../data/Urls';
import { NewsHeaderContent } from '../generated/NewsHeader';
import { PoorText } from '2mr-cms-frontend';

const PAGE_SIZE = 8;
type Props = {
    content: NewsHeaderContent,
}
export const NewsPage = ({ content }: Props) => {
    const [list, setList] = useState<NewsArticlePreviewContent[]>([]);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedAll, setLoadedAll] = useState(false);
    const navigate = useNavigate();
    let { tag } = useParams();
    if (tag && !content.filterTags.some(f => f.value === tag)) {
        navigate(NEWS_URL);
    }

    function fetchNews (off = offset, t = tag) {

        setIsLoading(true);

        fetchNewsList(off, PAGE_SIZE, t).then((r: NewsArticlePreviewContent[]) => {
            setList(l => {
                return [...l, ...r];
            });
            setOffset(o => {
                return o + PAGE_SIZE;
            });
            if (r.length < PAGE_SIZE) {
                setLoadedAll(true);
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        setOffset(0);
        setLoadedAll(false);
        setList([]);

        fetchNews(0, tag);
    }, [tag]);

    const btn = <Button disabled={isLoading} style={'air'} onClick={() => {
        fetchNews();
    }}>
        {
            content.loadMoreButtonLabel
                ? <PoorText content={content.loadMoreButtonLabel}/>
                : 'Load more'
        }
    </Button>;
    return (
        <>
            <Header {...content.header}/>
            <NewsTabs tabs={content.filterTags} active={tag}/>
            <RecentNewsBlock title={undefined} newsList={list} buttonLink={loadedAll ? null : btn}/>
        </>
    );
};
