import React from 'react';
import styles from './style.scss';
import { CompanyStoryContent, StoryCardContent } from '../../../../generated/Company';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

const StoryGridElement = (elem: StoryCardContent) => {
    const { ref } = useContentFromCMSRef(elem.image);
    return <li key={elem.title.value} className={styles.card}>
        <div ref={ref} className={styles.cardImage} style={imgUrlCssVar(
            '--background-image-url',
            elem.image.value
        )}></div>

        <div className={styles.cardText}>
            <div className={styles.cardDate}>
                <PoorText content={elem.date}/>
            </div>
            <div className={styles.cardTitle}>
                <PoorText content={elem.title}/>
            </div>
            <RichText className={styles.cardDescription} content={elem.description} element={'div'}/>
        </div>
    </li>;
};

export const StoryGrid = (props: CompanyStoryContent) => {

    const elems = props.list.map((elem) => {
        return <StoryGridElement {...elem}/>;
    });
    return (
        <div className={styles.block}>
            <div className={styles.container}>
                <h2 className={styles.title}>
                    <PoorText content={props.title}/>
                </h2>
                <RichText content={props.description} element={'div'} className={styles.description}/>
                <ul className={styles.grid}>
                    {elems}
                </ul>
            </div>
        </div>
    );
};

