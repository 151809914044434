import React from 'react';

import styles from './style.scss';
import { Tab } from '../../solutions/tabs/SolutionsTabs';
import { NEWS_URL } from '../../../../data/Urls';
import { PoorTextContent } from '../../../../generated/StdLib';

type Props = {
    tabs: PoorTextContent[],
    active?: string,
}
export const NewsTabs = (props: Props) => {

    const tabs = props.tabs.map((t, i) => {
        return <Tab key={i} title={t} url={NEWS_URL + '/' + t.value} active={props.active === t.value}/>;
    });
    tabs.unshift(
        <Tab key={-1} title={'All'} url={NEWS_URL} active={props.active === undefined}/>
    );

    return (
        <div className={styles.list}>
            {tabs}
        </div>
    );
};
