import React, { ReactNode } from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { Tags } from '../tags/Tags';
import { Optional, PoorTextContent } from '../../../generated/StdLib';
import { NewsArticlePreviewContent } from '../../../generated/NewsContent';
import { NEWS_SINGLE_BASE_URL, NEWS_URL } from '../../../data/Urls';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

const RecentNewsBlockElement = (card: NewsArticlePreviewContent) => {
    const { ref } = useContentFromCMSRef(card.imageUrl);
    const tagsList = card.tags.map(t => {
        return {
            label: t,
            url: NEWS_URL + '/' + t,
        };
    });
    const cardElem = <>
        <a
            ref={ref}
            href={NEWS_SINGLE_BASE_URL + card.slug}
            className={styles.cardImage}
            style={
                imgUrlCssVar(
                    '--background-image-url',
                    card.imageUrl.value
                )
            }
        />

        <Tags list={tagsList}/>
        <a href={NEWS_SINGLE_BASE_URL + card.slug} className={styles.cardTitle}>
            <PoorText content={card.title}/>
        </a>
        <time className={styles.cardDate}>
            <PoorText content={card.date}/>
        </time>
    </>;
    return <li key={card.slug}>{cardElem}</li>;
};

type Props = {
    title: Optional<PoorTextContent>,
    newsList: NewsArticlePreviewContent[],
    buttonLink: ReactNode,
}

export const RecentNewsBlock = (props: Props) => {

    const elems = props.newsList.map(card => {
        return <RecentNewsBlockElement {...card}/>;
    });
    return <div className={styles.news}>
        {props.title ?
            <h2 className={styles.title}>
                <PoorText content={props.title}/>
            </h2> : null
        }
        <ul className={styles.list}>
            {elems}
        </ul>
        {props.buttonLink}
    </div>;
};
