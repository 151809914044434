import React from 'react';
import styles from './ProjectHeader.scss';
import { ImageContent, PoorTextContent, RichTextContent } from '../../../../generated/StdLib';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

interface Props {
    tag: PoorTextContent;
    title: PoorTextContent;
    imgUrl: ImageContent;
    description: RichTextContent;
}

export const ProjectHeader = ({
    tag,
    title,
    imgUrl,
    description,
}: Props) => {
    const { ref } = useContentFromCMSRef(imgUrl);
    return (
        <div className={styles.projectHeader}>
            <div className={styles.tagWrapper}>
                <h4 className={styles.tag}><PoorText content={tag}/></h4>
            </div>
            <div className={styles.titleWrapper}>
                <h1 className={styles.title}><PoorText content={title}/></h1>
                <RichText element="p" className={styles.description} content={description}/>
            </div>
            <div className={styles.imageWrapper}>

                <img ref={ref} src={imgUrl.value} alt="Project Image" className={styles.image}/>
            </div>
        </div>
    );
};