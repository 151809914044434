import React from "react";
import styles from './ProjectFooter.scss';
import {ProjectUrl} from "../../../../generated/ProjectsContent";
import {Button} from "../../../controls/button/Button";
import {useNavigate} from "react-router-dom";
import { PoorText } from '2mr-cms-frontend';

interface Props {
    urls: ProjectUrl[]
}

export const ProjectFooter = ({urls}: Props) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    }

    return (
        <div className={styles.projectFooter}>
            <Button
                style={'air'}
                className={styles.button}
                onClick={handleBackClick}
            >
                Go Back
            </Button>
            {urls.map((url, index) => (
                <Button
                    style={'air'}
                    href={url.url}
                    key={index}
                    className={styles.button}
                >
                    <PoorText content={url.title}/> <span className={styles.arrow}></span>
                </Button>
            ))}
        </div>
    )
}