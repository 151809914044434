import { JsonObjectType, optional } from '2mr-cms-typegen';
import { defineType } from '2mr-cms-typegen';

const ContactUsCardContent: JsonObjectType = {
    title: 'text',
    description: 'text',
    links: [{
        label: 'text',
        href: 'url',
        arrow: optional('string'),
    }]
};
defineType(ContactUsCardContent, 'ContactUsCardContent', "ContactUsBlock.ts");

export const ContactUsBlockContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    cards: [ContactUsCardContent]
};

defineType(ContactUsBlockContent, 'ContactUsBlockContent', "ContactUsBlock.ts");


export const importContactUsBlockContent = () => {}
