import React from 'react';
import { IFeaturedSlide } from '../../../../../../generated/Home';
import styles from './FeaturedSlide.scss';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

interface Props {
    data: IFeaturedSlide;
}

export const FeaturedSlide = ({ data }: Props) => {
    const { ref } = useContentFromCMSRef(data.titleImage);
    return (
        <a href={data.url} className={styles.featuredSlide} target="_blank">
            <img
                ref={ref}
                src={data.titleImage.value}
                alt="Title"
                className={styles.title}
            />

            <h4 className={styles.description}><PoorText content={data.description}/></h4>
            <div className={styles.date}><PoorText content={data.date}/></div>
        </a>
    );
};
