import React from 'react';
import styles from './ProjectPageHeader.scss';
import { ImageContent, PoorTextContent } from '../../../../generated/StdLib';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

interface Props {
    imgUrl: ImageContent;
    title: PoorTextContent;
}

export const ProjectPageHeader = ({
    imgUrl,
    title
}: Props) => {
    const { ref } = useContentFromCMSRef(imgUrl);
    return (
        <div className={styles.projectPageHeader}>
            <div className={styles.imageWrapper}>
                <img ref={ref} src={imgUrl.value} alt="Background" className={styles.image}/>
            </div>

            <div className={styles.titleWrapper}>
                <h1 className={styles.title}><PoorText content={title}/></h1>
            </div>
        </div>
    );
};