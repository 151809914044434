import React, {useState} from "react";
import styles from './FeaturedSlider.scss';
import {SwiperSlide} from 'swiper/react';
import {FeaturedSlide} from "./slide/FeaturedSlide";
import {IFeaturedSlide} from "../../../../../generated/Home";
import {SliderNavigation} from "../../../../general/slider/navigation/SliderNavigation";
import {SliderPagination} from "../../../../general/slider/pagination/SliderPagination";
import {DEFAULT_SLIDER_STATE, Slider, SliderState, useSliderControls} from "../../../../general/slider/Slider";

interface Props {
    slides: IFeaturedSlide[];
}

export const FeaturedSlider = ({slides}: Props) => {
    const [state, setState] = useState<SliderState>(DEFAULT_SLIDER_STATE)

    const {handlePaginationClick, handleLeftClick, handleRightClick} = useSliderControls(state.ref);

    return (
        <div className={styles.featuredSlider}>
            <Slider
                setState={setState}
                className={styles.slider}
                spaceBetween={29}
                slidesPerView='auto'
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={`slide-${index}`} className={styles.slide}>
                        <FeaturedSlide data={slide}/>
                    </SwiperSlide>
                ))}
            </Slider>
            {state.pagination.total > 1 && (
                <>
                    <SliderPagination
                        current={state.pagination.current}
                        total={state.pagination.total}
                        onClick={(index) => handlePaginationClick(index)}
                        className={styles.pagination}
                    />
                    <SliderNavigation
                        color='black'
                        leftDisabled={state.navigation.left}
                        rightDisabled={state.navigation.right}
                        onLeftClick={handleLeftClick}
                        onRightClick={handleRightClick}
                    />
                </>
            )}
        </div>
    )
}