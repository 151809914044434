import React from 'react';

import styles from './Header.scss';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Button } from '../../../controls/button/Button';
import { HeaderContent } from '../../../../generated/Home';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

export const Header = ({
        superTitle,
        title,
        subTitle,
        button,
        backgroundUrl,
    }: HeaderContent
) => {

    const { ref } = useContentFromCMSRef(backgroundUrl);
    return <div
        className={styles.header}
        ref={ref}
        style={imgUrlCssVar(
            '--header-background-image-url',
            backgroundUrl.value
        )}
    >

        <div className={styles.contentWrapper}>
            <div className={styles.content}>
                <div className={styles.superTitle}>
                    <RichText
                        content={superTitle}
                        element={'span'}
                    />
                </div>
                <div className={styles.title}>
                    <RichText
                        content={title}
                        element={'span'}
                    />
                </div>
                <div className={styles.subTitle}>
                    <RichText content={subTitle} element={'span'}/>
                </div>
                <Button href={button.url} style={'filled'}>
                    <PoorText content={button.title}/>
                </Button>
            </div>
        </div>
    </div>;
};
