import { JsonObjectType, oneOf, optional } from '2mr-cms-typegen';
import { defineType } from '2mr-cms-typegen';


export const HeaderCommon: JsonObjectType = {
    title: 'richText',
    subTitle: optional('richText'),
    titleAfterSubtitle: 'boolean',
    backgroundUrl: 'image',
};

defineType(
    HeaderCommon,
    'HeaderCommon',
    'Common.ts'
);
export const HasTitleAndUrl: JsonObjectType = {
    title: 'text',
    url: 'url',
};

defineType(
    HasTitleAndUrl,
    'HasTitleAndUrl',
    'Common.ts'
);
export const SocialNetwork: JsonObjectType = {
    url: 'url',
    logoUrl: 'image',
};
defineType(
    SocialNetwork,
    'SocialNetwork',
    'Common.ts',
);
export const ShownOrHidden = oneOf('shown', 'hidden')
defineType(
    ShownOrHidden,
    'ShownOrHidden',
    'Common.ts',
);
export const PersonCardContent: JsonObjectType = {
    imageUrl: 'image',
    position: 'text',
    name: 'text',
    socials: [SocialNetwork],
};

defineType(
    PersonCardContent,
    'PersonCardContent',
    'Common.ts'
);

export const CardContent: JsonObjectType = {
    image: 'image',
    title: 'text',
    text: optional('richText'),
    description: optional('richText'),
    list: optional(['text']),
    buttonLink: optional({
        label: optional('text'),
        url: optional('url'),
    })
};
defineType(
    CardContent,
    'CardContent',
    'Common.ts'
);

export const SimpleCardContent: JsonObjectType = {
    title: optional('text'),
    description: optional('richText'),
    imageUrl: 'image',
};

defineType(
    SimpleCardContent,
    'SimpleCardContent',
    'Common.ts'
);

export const ThreeColumnGridBlockContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    cards: [SimpleCardContent]
};

defineType(
    ThreeColumnGridBlockContent,
    'ThreeColumnGridBlockContent',
    'Common.ts'
);

export const TwoColumnsCardContent: JsonObjectType = {
    title: 'text',
    description: 'richText',
    imageUrl: 'image',
    dark: 'boolean'
};

defineType(
    TwoColumnsCardContent,
    'TwoColumnsCardContent',
    'Common.ts'
);

