import React from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../../../utils/CssVar';
import { ShapeTheFutureContent, ShapeTheFutureItem } from '../../../../../generated/Solutions';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

const ShapeTheFutureItem = (elem: ShapeTheFutureItem) => {
    const { ref } = useContentFromCMSRef(elem.iconUrl);
    return <li className={styles.listElem}>
        <div ref={ref} className={styles.elemIcon} style={imgUrlCssVar(
            '--background-image-url',
            elem.iconUrl.value
        )}></div>

        <div className={styles.elemRight}>
            <RichText className={styles.elemDescription} content={elem.description} element={'div'}/>
            <RichText className={styles.elemBigText} content={elem.bigText} element={'div'}/>
        </div>
    </li>;
};

export const ShapeTheFuture = (props: ShapeTheFutureContent) => {

    const list = props.list.map((elem) => {
        return <ShapeTheFutureItem {...elem}/>;
    });
    return <div className={styles.block}>
        <div className={styles.container}>
            <h2 className={styles.title}>
                <PoorText content={props.title}/>
            </h2>
            <RichText className={styles.description} content={props.description} element={'div'}/>
            <ul className={styles.list}>
                {list}
            </ul>
        </div>
    </div>;
};
