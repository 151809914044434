import React from 'react';
import { useLocation } from 'react-router-dom';
import { SolutionsMetaversePage } from './Metaverse';
import { SolutionsLaunchpadPage } from './Launchpad';
import { SolutionsContent } from '../../generated/Solutions';
import { ContactUsBlockContent } from '../../generated/ContactUsBlock';

interface Props {
    solutionsContent: SolutionsContent,
    contactUsBlockContent: ContactUsBlockContent,
}

export const SolutionsPage = ({
    solutionsContent,
    contactUsBlockContent
}: Props) => {
    const location = useLocation();

    switch (location.hash) {
        case '#marketplace':
            return <SolutionsMetaversePage content={
                {
                    ...solutionsContent.metaverse,
                    contactUsBlock: contactUsBlockContent,
                    tabs: solutionsContent.tabs,
                }

            }/>;
        default:
            return <SolutionsLaunchpadPage content={
                {
                    ...solutionsContent.launchpad,
                    contactUsBlock: contactUsBlockContent,
                    tabs: solutionsContent.tabs,
                }

            }/>;
    }
};
