import React, { useEffect, useState } from 'react';
import styles from './OurProjects.scss';
import { ProjectPreviewContent } from '../../../generated/ProjectsContent';
import { fetchAllProjects } from '../../../utils/fetch';
import { Link } from 'react-router-dom';
import { PROJECT_BASE_URL } from '../../../data/Urls';
import { PoorTextContent } from '../../../generated/StdLib';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

interface Props {
    exceptSlug?: string;
    title?: PoorTextContent;
}

export const OurProjects = ({
    exceptSlug,
    title
}: Props) => {
    const [projects, setProjects] = useState<ProjectPreviewContent[]>([]);

    useEffect(() => {
        fetchAllProjects()
            .then(response => {
                setProjects(response.filter(p => p.slug !== exceptSlug));
            })
            .catch(e => {
                console.log(e);
            });
    }, [exceptSlug]);

    if (projects.length === 0) {
        return null;
    }

    return (
        <div className={styles.ourProjects}>
            <h2 className={styles.title}>{
                title
                    ? <PoorText content={title}/>
                    : 'Our Projects'
            }</h2>
            <div className={styles.cardsWrapper}>
                {projects.map((p, i) => (
                    <ProjectCard data={p} key={i}/>
                ))}
            </div>
        </div>
    );
};

interface CardProps {
    data: ProjectPreviewContent;
}

const ProjectCard = ({ data }: CardProps) => {
    const { ref } = useContentFromCMSRef(data.headerImage);
    return (
        <Link to={`${PROJECT_BASE_URL}${data.slug}`} className={styles.projectCard}>
            <div className={styles.imageWrapper}>
                <img ref={ref} src={data.headerImage.value} alt="Project image" className={styles.image}/>
            </div>

            <div className={styles.projectTitleWrapper}>
                <h2 className={styles.projectTitle}><PoorText content={data.title}/></h2>
            </div>
            <div className={styles.cardHover}>
                <div>
                    <h4 className={styles.cardHoverTitle}>
                        <PoorText content={data.title}/>
                    </h4>
                    <RichText
                        element="p"
                        className={styles.cardHoverDesc}
                        content={data.description}
                    />
                </div>
                <div className={styles.cardHoverBottom}>
                    Learn more
                    <svg
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.arrow}
                    >
                        <path
                            d="M0.388027 4.07677C0.311264 4.15351 0.258985 4.25129 0.2378 4.35774C0.216615 4.4642 0.227477 4.57454 0.269013 4.67482C0.310548 4.7751 0.380892 4.86082 0.471144 4.92112C0.561397 4.98141 0.667503 5.01359 0.776045 5.01358L8.82787 5.01363L5.88456 7.95693C5.78165 8.05984 5.72384 8.19941 5.72384 8.34495C5.72384 8.49048 5.78166 8.63005 5.88456 8.73296C5.98747 8.83587 6.12705 8.89368 6.27258 8.89368C6.41811 8.89368 6.55768 8.83587 6.66059 8.73296L10.543 4.85055C10.6445 4.74784 10.7014 4.60925 10.7014 4.46484C10.7014 4.32043 10.6445 4.18185 10.543 4.07913L6.66054 0.196675C6.55763 0.0937669 6.41806 0.0359533 6.27253 0.0359524C6.127 0.0359515 5.98742 0.0937635 5.88452 0.19667C5.78161 0.299577 5.7238 0.439149 5.7238 0.584683C5.7238 0.730216 5.78161 0.869789 5.88452 0.972696L8.82798 3.91616L0.776039 3.91599C0.70396 3.9159 0.632575 3.93006 0.565987 3.95766C0.499398 3.98525 0.43892 4.02573 0.388027 4.07677Z"
                            fill="white"
                        />
                    </svg>
                </div>

            </div>
        </Link>
    );
};