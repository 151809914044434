import React from "react";
import styles from "./style.scss";
import { CompanyValuesContent} from "../../../../generated/Company";
import {KeyObjectiveBlock} from "../../solutions/launchpad/keyObjectiveBlock/KeyObjectiveBlock";


export const CompanyValues = (props: CompanyValuesContent) => {
    return <KeyObjectiveBlock {...props} subheader={undefined} theme={'accent'}/>
};

