import styles from './NavPanel.scss';
import React, { useEffect, useRef } from 'react';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Button } from '../../../controls/button/Button';
import { NavLink } from '../navLink/NavLink';
import { Link, useLocation } from 'react-router-dom';
import { NavigationContent } from '../../../../generated/Navigation';
import { HOME_PAGE_URL } from '../../../../data/Urls';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export type NavPanelProps = NavigationContent & {
    onOpenMenu: () => void,
}

export const NavPanel = ({
    sections,
    contact,
    smallLogoUrl,
    menuIconUrl,
    onOpenMenu,
}: NavPanelProps) => {

    const location = useLocation();

    const ref = useRef<HTMLDivElement>(null);
    const handle = () => {
        const scroll = Math.min(1, window.scrollY / window.innerHeight);
        ref.current!.style.setProperty(
            '--nav-desktop-scroll',
            scroll.toString()
        );
    };
    useEffect(() => {
        handle();
        window.addEventListener('scroll', handle);
        return () => {
            window.removeEventListener('scroll', handle);
        };
    });

    const {
        ref: ref2,
    } = useContentFromCMSRef(smallLogoUrl);

    const {
        ref: ref3,
    } = useContentFromCMSRef(menuIconUrl);

    return <div className={styles.panel} ref={ref}>
        <div className={styles.content}>
            <Link ref={ref2} to={HOME_PAGE_URL} className={styles.logo} style={imgUrlCssVar(
                '--nav-logo-url',
                smallLogoUrl.value
            )}>
            </Link>
            <div className={styles.sections}>
                {sections.map((s, idx) => <NavLink
                    type={'primary'}
                    title={s.title}
                    href={s.url}
                    opaq={
                        s.url !== location.pathname
                    }
                    key={idx}
                />)}
            </div>
            <div className={styles.contact}>
                <Button
                    href={contact.url}
                    style={'air'}
                >
                    <PoorText content={contact.title}/>
                </Button>
            </div>
            <button
                className={styles.menu}
                ref={ref3}
                style={imgUrlCssVar(
                    '--nav-menu-icon-url',
                    menuIconUrl.value,
                )}
                onClick={onOpenMenu}
            />
        </div>
    </div>;

};
