import React, {useEffect, useState} from "react";
import styles from './ProjectHighlight.scss';
import {ProjectHighlightContent} from "../../../../generated/Home";
import {ProjectHighlightSlider} from "./slider/ProjectHighlightSlider";
import {ProjectHighlightSlide} from "./slider/slide/ProjectHighlightSlide";
import {fetchAllProjects} from "../../../../utils/fetch";
import {ProjectPreviewContent} from "../../../../generated/ProjectsContent";
import { PoorText } from '2mr-cms-frontend';

export const ProjectHighlight = ({title}: ProjectHighlightContent) => {
    const [projects, setProjects] = useState<ProjectPreviewContent[]>([]);

    useEffect(() => {
        fetchAllProjects()
            .then(response => {
                setProjects(response);
            })
            .catch(e => {
                console.log(e);
            })
    }, [])

    if (projects.length === 0) return null;

    return (
        <div className={styles.projectHighlight}>
            <div className={styles.titleWrapper}>
                <h2 className={styles.title}><PoorText content={title}/></h2>
            </div>
            <ProjectHighlightSlider slides={projects} className={styles.slider}/>
            <div className={styles.tabletSlides}>
                {projects.map((project, index) => (
                    <ProjectHighlightSlide data={project} key={index} className={styles.tabletSlide}/>
                ))}
            </div>
        </div>
    )
}