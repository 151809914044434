import React from 'react';
import styles from './style.scss';
import { PersonCardContent } from '../../../generated/Common';
import { Social } from '../socialNetworks/SocialNetworks';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export const PersonCard = (props: PersonCardContent) => {

    const { ref } = useContentFromCMSRef(props.imageUrl);
    return <div className={styles.card}>
        <div
            className={styles.image}
            ref={ref}
            style={imgUrlCssVar(
                '--image-url',
                props.imageUrl.value
            )}>

        </div>
        <div
            className={styles.position}
        >
            <PoorText content={props.position}/>
        </div>
        <div
            className={styles.name}
        ><PoorText content={props.name}/></div>
        <div className={styles.socials}>

            {
                props.socials.map((s, i) => <Social
                    key={i}
                    {...s}
                />)
            }

        </div>
    </div>;
};
