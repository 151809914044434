import React from 'react';
import { FAQContent } from '../generated/FAQ';
import { Questions } from '../component/pages/faq/questions/Questions';
import { Header } from '../component/general/header/Header';

export type FAQProps = {
    content: FAQContent,
}

export const FAQ = ({
    content,
}: FAQProps) => {

    return <>
        <Header {...content.header} />
        <Questions defaultState={content.defaultAnswersState} chapters={content.chapters}/>
    </>;
};
