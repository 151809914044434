import React from "react";
import styles from "./style.scss";
import {AdvisoryBlockContent} from "../../../../../generated/Solutions";
import {PersonCard} from "../../../../general/personCard/PersonCard";
import { PoorText, RichText } from '2mr-cms-frontend';

export const Advisory = (props: AdvisoryBlockContent) => {

    const cards = props.personCards.map((c, i) => {
        return <li className={styles.cardElem} key={i}>
            <PersonCard {...c} />
        </li>
    })
    return <div className={styles.advisory}>
        <div className={styles.container}>
            <h2 className={styles.title}><PoorText content={props.title}/></h2>
            <RichText className={styles.subtitle} content={props.subtitle} element={'div'}/>
            <RichText className={styles.description} content={props.description} element={'div'}/>

            <ul className={styles.cardList}>
                {cards}
            </ul>
        </div>

    </div>;
};
